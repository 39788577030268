import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Card,
  CardContent,
  Avatar,
  CircularProgress,
  Chip,
  Divider,
  Grid as MuiGrid,
  Paper
} from "@mui/material";
import { 
  Building2,
  Shield, 
  Stethoscope
} from "lucide-react";
// import { useAuth } from "../../services/AuthContext";
import supabaseSingleton from "../../supabaseClient";


const UserProfilePage = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // const { userData } = useAuth();


  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      setLoading(true);
      const { data: { user: authUser } } = await supabaseSingleton.auth.getUser();

      if (!authUser) {
        setError("No authenticated user found");
        return;
      }

     // First get the user and clinician data
    const { data: applicationUser, error: applicationUserError } = await supabaseSingleton
    .from("application_user")
    .select(`
      *,
      UserRole:application_user_role (
        role:application_role (
          id,
          name,
          description
        )
      ),
      clinician (
        id,
        clinicianType,
        isActive,
        facilityIdPrimary
      )
    `)
    .eq("authUid", authUser.id)
    .single();

    //check for errors (no rows returned)
    if(!applicationUser) {
      setError("No user data found");
      return;
    }

    // If we have a facilityIdPrimary, fetch the facility details
    if (applicationUser?.clinician?.facilityIdPrimary) { 
    const { data: facilityData, error: facilityError } = await supabaseSingleton
      .from("facility")
      .select("*")
      .eq("id", "44140f13-8ece-4692-bff0-51f5623503dc" )
      .single();
 
    console.log('Facility Query Error:', facilityError);
    console.log('Facility Data:', facilityData);
    console.log('Number of results:', facilityData?.length);


    if (!facilityError) {
      applicationUser.clinician.facility = facilityData;
    }
    }

    if (applicationUserError) throw applicationUserError;

      setUser({
        ...authUser,
        ...applicationUser
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
      <CircularProgress />
    </Box>
  );

  if (error) return (
    <Box p={3}>
      <Typography color="error" variant="h6">
        Error loading profile: {error}
      </Typography>
    </Box>
  );

  if (!user) return (
    <Box p={3}>
      <Typography>No user data available</Typography>
    </Box>
  );

  return (
    <Box sx={{ maxWidth: 800, margin: "auto", mt: 4, px: 2 }}>
      <Card elevation={2} sx={{ borderRadius: 2 }}>
        <CardContent sx={{ p: 4 }}>
          {/* Basic Info Section */}
          <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
            <Avatar 
              sx={{ 
                width: 80, 
                height: 80, 
                mr: 3,
                bgcolor: 'primary.main',
                fontSize: '2rem'
              }}
            >
              {user.firstName?.[0]?.toUpperCase() || user.email?.[0]?.toUpperCase()}
            </Avatar>
            <Box>
              <Typography variant="h3" sx={{ fontSize: '2.5rem', fontWeight: 500, mb: 1 }}>
                {user.firstName} {user.lastName}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ mb: 0.5 }}>
                {user.email}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Member since {new Date(user.createdAt).toLocaleDateString()}
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ my: 3 }} />

          {/* Application Roles Section */}
          <Box sx={{ mb: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Shield size={24} className="mr-2" />
              <Typography variant="h4" sx={{ fontSize: '1.75rem', fontWeight: 500 }}>
                Application Roles
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {user.UserRole?.map((userRole) => (
                <Chip 
                  key={userRole.role.id}
                  label={userRole.role.name}
                  color={userRole.role.name === 'Admin' ? 'error' : 'primary'}
                  variant="outlined"
                  sx={{ 
                    borderRadius: '16px',
                    px: 1,
                    fontSize: '0.9rem'
                  }}
                />
              ))}
            </Box>
          </Box>

          {/* Clinician Information */}
          {user.clinician && (
            <Box sx={{ mb: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <Stethoscope size={24} className="mr-2" />
                <Typography variant="h4" sx={{ fontSize: '1.75rem', fontWeight: 500 }}>
                  Clinician Details
                </Typography>
              </Box>
              
              <MuiGrid container spacing={3}>
                <MuiGrid item xs={12} md={6}>
                  <Paper 
                    elevation={1} 
                    sx={{ 
                      p: 3,
                      height: '100%',
                      borderRadius: 2
                    }}
                  >
                    <Typography variant="body1" color="text.secondary" gutterBottom>
                      Clinician Type
                    </Typography>
                    <Typography variant="h6" sx={{ fontSize: '1.5rem' }}>
                      {user.clinician?.clinicianType || 'Not specified'}
                    </Typography>
                  </Paper>
                </MuiGrid>
                
                <MuiGrid item xs={12} md={6}>
                  <Paper 
                    elevation={1} 
                    sx={{ 
                      p: 3,
                      height: '100%',
                      borderRadius: 2
                    }}
                  >
                    <Typography variant="body1" color="text.secondary" gutterBottom>
                      Status
                    </Typography>
                    <Chip 
                      label={user.clinician?.isActive ? 'Active' : 'Inactive'}
                      color={user.clinician?.isActive ? 'success' : 'error'}
                      sx={{ 
                        borderRadius: '16px',
                        fontSize: '0.9rem',
                        px: 2
                      }}
                    />
                  </Paper>
                </MuiGrid>
              </MuiGrid>
            </Box>
          )}







          {/* Primary Facility Section */}
          {user.clinician?.facility && (
            <>
              <Divider sx={{ my: 3 }} />
              <Box sx={{ mb: 4 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                  <Building2 size={24} className="mr-2" />
                  <Typography variant="h4" sx={{ fontSize: '1.75rem', fontWeight: 500 }}>
                    Primary Facility
                  </Typography>
                </Box>
                
                <MuiGrid container spacing={3}>
                  <MuiGrid item xs={12} md={6}>
                    <Paper 
                      elevation={1} 
                      sx={{ 
                        p: 3,
                        height: '100%',
                        borderRadius: 2
                      }}
                    >
                      <Typography variant="body1" color="text.secondary" gutterBottom>
                        Facility Name
                      </Typography>
                      <Typography variant="h6" sx={{ fontSize: '1.5rem' }}>
                        {user.clinician?.facility?.name || 'Not Assigned'}
                      </Typography>

                      {/* Facility Description */}
                      <Typography variant="h8" color="text.secondary" sx={{ mt: 1 }}>
                        {user.clinician?.facility?.description || 'No description available'}
                      </Typography>
                    </Paper>
                  </MuiGrid>
                  
                  <MuiGrid item xs={12} md={6}>
                    <Paper 
                      elevation={1} 
                      sx={{ 
                        p: 3,
                        height: '100%',
                        borderRadius: 2
                      }}
                    >
                      <Typography variant="body1" color="text.secondary" gutterBottom>
                        Location
                      </Typography>
                      {/* Street Address */}
                      <Typography variant="h6" sx={{ fontSize: '1.5rem' }}>
                        {user.clinician?.facility?.address1 || 'Not Specified'}
                      </Typography>
                      <Typography variant="h6" sx={{ fontSize: '1.5rem' }}>
                        {user.clinician?.facility?.city || 'Not Specified'}
                      </Typography>
                    </Paper>
                  </MuiGrid>

                  {user.clinician?.facility?.contactPerson && (
                    <MuiGrid item xs={12}>
                      <Paper 
                        elevation={1} 
                        sx={{ 
                          p: 3,
                          height: '100%',
                          borderRadius: 2
                        }}
                      >
                        <Typography variant="body1" color="text.secondary" gutterBottom>
                          Contact Person
                        </Typography>
                        <Typography variant="h6" sx={{ fontSize: '1.5rem' }}>
                          {user.clinician?.facility?.contactPerson}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                          {user.clinician?.facility?.contactEmail}
                        </Typography>
                      </Paper>
                    </MuiGrid>
                  )}
                </MuiGrid>
              </Box>
            </>
          )}






        </CardContent>
      </Card>
    </Box>
  );
};

export default UserProfilePage;