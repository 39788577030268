import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import "./Header.scss";
import { useUser } from "@supabase/auth-helpers-react";
import { useUserContext } from "../../contexts/UserContext";

const Header: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const user = useUser();
  const { userFacility } = useUserContext();

  // const { userData } = useAuth();

  //use effect to load user data
  useEffect(() => {
    LoadPageData();
  }, []);

  const LoadPageData = async () => {
    const x = userFacility;
    // const testU = userData;
    //load user data
    // const { data: { user: authUser } } = await supabase.auth.getUser();
    // if (!authUser) {
    //   return;
    // }else{
    //   setUserData(authUser);
    // }
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToProfilePage = () => {
    //navigate to profile page (/user-profile)
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  const drawerContent = (
    <div
      className="drawer-content"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <IconButton className="drawer-close-btn" onClick={toggleDrawer(false)}>
        <CloseIcon />
      </IconButton>
      <List>
        {/* <ListItem button component={Link} to="/auth-selector">
          <ListItemText primary="Auth Selector" />
        </ListItem> */}

        <ListItem button component={Link} to="/auth-selector">
          <ListItemText primary="Auth Selector" />
        </ListItem>

        <ListItem button component={Link} to="/admin">
          <ListItemText primary="Admin" />
        </ListItem>

        <ListItem button component={Link} to="/dashboard">
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button component={Link} to="/patients">
          <ListItemText primary="Patients" />
        </ListItem>
        <ListItem button component={Link} to="/schedule">
          <ListItemText primary="Schedule" />
        </ListItem>
        <ListItem button component={Link} to="/treatments">
          <ListItemText primary="Treatments" />
        </ListItem>
        <ListItem button component={Link} to="/labworks">
          <ListItemText primary="Labworks" />
        </ListItem>
        <ListItem button component={Link} to="/accounts">
          <ListItemText primary="Accounts" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <AppBar position="static" className="app-bar">
        <Toolbar className="toolbar">
          <Typography variant="h6" className="title">
            <span className="logo">Dental Practice</span>
          </Typography>
          {isMobile ? (
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <div className="nav-links">
              <Button color="inherit" component={Link} to="/auth-selector">
                Auth Selector
              </Button>
              <Button color="inherit" component={Link} to="/admin">
                Admin
              </Button>
              <Button color="inherit" component={Link} to="/dashboard">
                Dashboard
              </Button>
              <Button color="inherit" component={Link} to="/patients">
                Patients
              </Button>
              <Button color="inherit" component={Link} to="/schedule">
                Schedule
              </Button>
              <Button color="inherit" component={Link} to="/treatments">
                Treatments
              </Button>
              <Button color="inherit" component={Link} to="/labworks">
                Labworks
              </Button>
              <Button color="inherit" component={Link} to="/accounts">
                Accounts
              </Button>
            </div>
          )}
          <div className="profile-menu">
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem component={Link} to="/user-profile">
                Profile
              </MenuItem>
              <MenuItem component={Link} to="/user-profile">
                My Account
              </MenuItem>
            </Menu>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography variant="body1" className="user-name">
                {user?.email ? user?.email : "Not Signed In"}
              </Typography>
              <Typography variant="body1" className="user-name">
                {userFacility?.name} 
              </Typography>
            </Box>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerContent}
      </Drawer>
    </>
  );
};

export default Header;
