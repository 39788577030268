import React, { useState, useEffect } from 'react';
import { StorageError, FileObject } from '@supabase/storage-js';
import supabaseSingleton from '../../supabaseClient';


const FileList: React.FC = () => {
  const [files, setFiles] = useState<FileObject[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchFiles = async () => {


      try {
        const { data, error } = await supabaseSingleton
          .storage
          .from('docs')
          .list('1', {
            limit: 100,
            offset: 0,
            sortBy: { column: 'name', order: 'asc' },
          });

        if (error) {
          throw error;
        }

        setFiles(data || []);
      } catch (err) {
        setError('Failed to fetch files');
        console.error('Error fetching files:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchFiles();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h2>Files in docs/1:</h2>
      <ul>
        {files.map((file) => (
          <li key={file.id}>
            {file.name} - {file.metadata?.size ? (file.metadata.size / 1024).toFixed(2) + ' KB' : 'Size unknown'}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FileList;