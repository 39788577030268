import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import supabase  from '../../../supabaseClient';
import Banner from '../../banner/Banner';

import DescriptionIcon from '@mui/icons-material/Description';
import { 
  Button, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
  Drawer, 
  Typography, 
  Box,

} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import AssignmentIcon from '@mui/icons-material/Assignment';
import CloseIcon from '@mui/icons-material/Close';
import AssignedFormResults from './AssignedFormResults';
import SendIcon from '@mui/icons-material/Send';

interface AssignedForm {
  id: string;
  name: string;
  status: string;
  assigned_at: string;
  responses: { [key: string]: string };
}

interface AvailableForm {
  id: string  ;
  name: string;
}


const FormsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [assignedForms, setAssignedForms] = useState<AssignedForm[]>([]);
  const [availableForms, setAvailableForms] = useState<AvailableForm[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState<boolean>(false);
  const [selectedForm, setSelectedForm] = useState<AssignedForm | null>(null);



  useEffect(() => {
    fetchAssignedForms();
    fetchAvailableForms();
  }, [id]);

  const fetchAssignedForms = async () => {
    const { data, error } = await supabase
      .from('form_assignments')
      .select(`
        id,
        assignedform:form_id(name),
        form_status,
        assigned_at,
        responses
      `)
      .eq('patient_id', id);

    
    if (error) {
      console.error('Error fetching assigned forms:', error);
    } else {
      setAssignedForms(data.map(item => ({
        id: item.id,
        name: "test",// item.assignedform.name,
        status: item.form_status,
        assigned_at: item.assigned_at,
        responses: item.responses? item.responses : {}
      })) || []);
    }
  };


  const handleClickOpen = (form: AssignedForm) => {
    setSelectedForm(form);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const fetchAvailableForms = async () => {
    const { data, error } = await supabase
      .from('assignable_form')
      .select('id, name');

    if (error) {
      console.error('Error fetching available forms:', error);
    } else {
      setAvailableForms(data || []);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params: GridRenderCellParams<AssignedForm>) => (
        <IconButton onClick={() => handleClickOpen(params.row)} color="primary">
          <AssignmentIcon />
        </IconButton>
      ),
    },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'status', headerName: 'Status', width: 150 },
    { 
      field: 'assigned_at', 
      headerName: 'Assigned At', 
      width: 200,
      valueFormatter: (params: { value: string }) => new Date(params.value).toLocaleString(),
    },
    { field: 'id', headerName: 'ID', width: 200 },
  ];

  const assignForm = async (formId: string) => {  

    const { error } = await supabase
      .from('form_assignments')
      .insert({
        patient_id: parseInt(id!),
        assignedform_id: formId,
        form_status: 'pending'
      });

    if (error) {
      console.error('Error assigning form:', error);
    } else {
      fetchAssignedForms();
      setDialogOpen(false);
    }
  };

  const handleNewClick = () => {
    setDialogOpen(true);
  };

  const filteredForms = availableForms.filter(form =>
    form.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <Banner
        icon={DescriptionIcon}
        title="Forms"
        subtitle="View and manage patient forms"
        buttons={
          <Box display="flex" alignItems="center">
            <TextField
              size="small"
              placeholder="Search forms"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ mr: 2 }}
            />
            <Button variant="contained" color="primary" onClick={handleNewClick}>
              New
            </Button>
          </Box>
        }
      />
       <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={assignedForms}
          columns={columns}
          // pageSize={5}
          // rowsPerPageOptions={[5, 10, 20]}
          // checkboxSelection={false}
          // disableSelectionOnClick
        />
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h6">Form Results</Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedForm && <AssignedFormResults data={selectedForm.responses} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

<Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
      <DialogContent>
          <List>
            {filteredForms.map((form) => (
              <ListItem 
                key={form.id}
                secondaryAction={
                  <IconButton edge="end" aria-label="assign" onClick={() => assignForm(form.id)}>
                    <SendIcon />
                  </IconButton>
                }
              >
                <ListItemText primary={form.name} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FormsPage;