import React, { useState } from 'react';
import { Box, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import NewExaminationModal from './NewExaminationModal';
import { ExaminationsSectionProps } from '../../types';


const ExaminationsSection: React.FC<ExaminationsSectionProps> = ({ examinations }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  const handleSelectType = (type: string) => {
    console.log(`Selected examination type: ${type}`);
    setModalOpen(false);
    // Handle the selected type here, for example, navigate to a form for the selected type
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h5">Examinations</Typography>
        <Button variant="contained" color="primary" onClick={handleOpenModal}>
          New Examination
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Filled By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {examinations.map((exam, index) => (
              <TableRow key={index}>
                <TableCell>{exam.type} - {index + 1}</TableCell>
                <TableCell>{exam.date}</TableCell>
                <TableCell>{exam.type}</TableCell>
                <TableCell>{exam.filledBy}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <NewExaminationModal open={modalOpen} handleClose={handleCloseModal} handleSelect={handleSelectType} />
    </Box>
  );
};

export default ExaminationsSection;
