import React from 'react';
import Banner from '../banner/Banner';
import HealingIcon from '@mui/icons-material/Healing';

const TreatmentsPage: React.FC = () => {
  return (
    <div>
      <Banner
        icon={HealingIcon}
        title="Treatments"
        subtitle="View and manage patient treatments"
      />
    </div>
  );
};

export default TreatmentsPage;
