import React from 'react';
import Banner from '../banner/Banner';
import BiotechIcon from '@mui/icons-material/Biotech';

const LabworksPage: React.FC = () => {
  return (
    <div>
      <Banner
        icon={BiotechIcon}
        title="Labworks"
        subtitle="View and manage patient labworks"
      />
    </div>
  );
};

export default LabworksPage;
