import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Radio,
  TextField,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import Banner from "../banner/Banner";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import "./IntraOralExam.scss";
import IntraOralDiagram from "./IntraOralDiagram";
import supabase from "../../supabaseClient";
import { FormStructure } from "../../types";



const ExtraOralExamForm: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [currentSectionIndex, setCurrentSectionIndex] = useState<number>(0);
  const [formStructure, setFormStructure] = useState<FormStructure | null>(null);
  const [formData, setFormData] = useState<Record<string, {
    questionId: string;
    version: number;
    value: any;
  }>>({});

  const { control, handleSubmit } = useForm();

  useEffect(() => {
    fetch("/forms/extraOral.json")
      .then((response) => response.json())
      .then((data: FormStructure) => {
        console.log("Fetched form structure:", data);
        setFormStructure(data);
      })
      .catch((error) => console.error("Error fetching form structure:", error));
  }, []);

  const onSubmit = async (data: any) => {
    debugger;
    console.log("Submitted data:", formData);
    
    try {
      const { data: insertData, error } = await supabase
        .from('ExtraOralExam')
        .insert({ formData: formData, patientId: id })
        .single();

      if (error) throw error;

      console.log("Data saved to Supabase:", insertData);
      alert("Extra Oral Examination submitted successfully!");
      navigate("../examinations");
    } catch (error) {
      console.error("Error saving to Supabase:", error);
      alert("Error submitting examination. Please try again.");
    }
  };

  const handleNext = () => {
    if (formStructure && currentSectionIndex < formStructure.sections.length - 1) {
      setCurrentSectionIndex(currentSectionIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentSectionIndex > 0) {
      setCurrentSectionIndex(currentSectionIndex - 1);
    }
  };

  const handleCancel = () => {
    navigate("../examinations");
  };

  if (!formStructure) return <div>Loading...</div>;

  const currentSection = formStructure.sections[currentSectionIndex];

  return (
    <Box className="intra-oral-exam">
      <Banner
        icon={MedicalServicesIcon}
        title="Examination - Extra Oral"
        subtitle="Fill in the form below to complete the Extra Oral examination for the patient."
      />


<Box display="flex" justifyContent="space-between" p={2}>
          <Button onClick={handlePrevious} disabled={currentSectionIndex === 0}>
            Previous
          </Button>
          <Box>
            <Button onClick={handleCancel} sx={{ mr: 1 }}>
              Cancel
            </Button>
            {currentSectionIndex === formStructure.sections.length - 1 ? (
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            ) : (
              <Button onClick={handleNext} variant="contained">
                Next
              </Button>
            )}
          </Box>
        </Box>

        
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" p={2}>
          <Box flexGrow={1}>
            <Typography variant="h6">{currentSection.title}</Typography>
            {currentSection.fields.map((field) => (
              <Box key={field.id} mb={4}>
                <Typography variant="subtitle1">{field.question}</Typography>
                <Controller
  name={`${currentSection.id}.${field.id}`}
  control={control}
  defaultValue=""
  rules={field.validation}
  render={({ field: { onChange, value } }) => {
    const handleChange = (e: any) => {
      const newValue = e.target.value;
      onChange(newValue);
      setFormData((prev) => ({
        ...prev,
        [`${currentSection.id}.${field.id}`]: {
          questionId: field.questionId,
          version: field.version,
          value: newValue,
        },
      }));
    };


                    const currentValue = formData[`${currentSection.id}.${field.id}`]?.value || "";

                    switch (field.type) {
                      case "radio":
                        return (
                          <RadioGroup value={currentValue} onChange={handleChange}>
                            {field.options?.map((option) => (
                              <FormControlLabel
                                key={option}
                                value={option}
                                control={<Radio />}
                                label={option}
                              />
                            ))}
                          </RadioGroup>
                        );
                      case "textarea":
                        return (
                          <TextField
                            fullWidth
                            multiline
                            rows={4}
                            value={currentValue}
                            onChange={handleChange}
                          />
                        );
                      case "text":
                      default:
                        return (
                          <TextField
                            fullWidth
                            value={currentValue}
                            onChange={handleChange}
                          />
                        );
                    }
                  }}
                />
              </Box>
            ))}
          </Box>
          <Box width="40%">
          
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" p={2}>
          <Button onClick={handlePrevious} disabled={currentSectionIndex === 0}>
            Previous
          </Button>
          <Box>
            <Button onClick={handleCancel} sx={{ mr: 1 }}>
              Cancel
            </Button>
            {currentSectionIndex === formStructure.sections.length - 1 ? (
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            ) : (
              <Button onClick={handleNext} variant="contained">
                Next
              </Button>
            )}
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default ExtraOralExamForm;
