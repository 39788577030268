import React from 'react';
import Banner from '../banner/Banner';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';

const PrescriptionsPage: React.FC = () => {
  return (
    <div>
      <Banner
        icon={LocalPharmacyIcon}
        title="Prescriptions"
        subtitle="View and manage patient prescriptions"
      />
    </div>
  );
};

export default PrescriptionsPage;
