import React from 'react';
import DetailSection from '../DetailSection';
import { Patient } from '../../types';
import { Typography } from '@mui/material';

interface PatientDetailsSectionProps {
  patient: Patient | null;
}

const PatientDetailsSection: React.FC<PatientDetailsSectionProps> = ({ patient }) => {
  if (!patient) {
    return <Typography>Loading...</Typography>;
  }

  const personalDetails = [
    { label: 'Patient ID', value: patient.id },
    { label: 'WhatsApp', value: patient.whatsappNumber },
    { label: 'First Name', value: patient.firstName },
    { label: 'Email', value: patient.email },
    { label: 'Last Name', value: patient.lastName },
    { label: 'Address', value: patient.address1 },
    { label: 'Gender', value: patient.gender },
    { label: 'Birth Date', value: new Date(patient.dateOfBirth as string).toLocaleDateString() },
    { label: 'Mobile', value: patient.phoneNumber1 },
    { label: 'City', value: patient.city },
    { label: 'District', value: patient.district },
    { label: 'Postal Code', value: patient.postalCode },
  ];

  return <DetailSection title="Personal Information" details={personalDetails} />;
};

export default PatientDetailsSection;
