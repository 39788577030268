// UppyUploader.tsx
import React, { useEffect, useRef } from "react";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import Tus from "@uppy/tus";
import { Box, Button } from "@mui/material";
import { getAccessToken, supabaseKey } from "../../supabaseClient";
import { generateValidSupabaseFileName } from "../../utilities/my-utils";

import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";

interface UppyUploaderProps {
  patientId: string;
  category: string;
  onUploadComplete: () => void;
  onFileUploadSuccess: (file: any, response: any) => void;
}

const projectUrl = process.env.REACT_APP_SUPABASE_URL;

const UppyUploader: React.FC<UppyUploaderProps> = ({
  patientId,
  category,
  onUploadComplete,
  onFileUploadSuccess,
}) => {
  const uppyRef = useRef<Uppy | null>(null);
  const dashboardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const initializeUppy = async () => {
      try {
        // Assuming you have a function to get the session and access token
        // const accessToken = await getAccessToken();
        const accessToken = ""; //TODO - get access token

        uppyRef.current = new Uppy({
          restrictions: {
            maxFileSize: 10 * 1024 * 1024,
            maxNumberOfFiles: 10,
            // allowedFileTypes: ['image/*', 'application/pdf', 'text/*']
          },
        })
          .use(Dashboard, {
            inline: true,
            target: dashboardRef.current,
            showProgressDetails: true,
            height: 350,
            width: "100%",
            theme: "light",
          })
          .use(Tus, {
            endpoint: `${projectUrl}/storage/v1/upload/resumable`,
            headers: {
              Authorization: `Bearer ${accessToken}`,
              apikey: supabaseKey,
            },
            chunkSize: 6 * 1024 * 1024,
            allowedMetaFields: [
              "bucketName",
              "objectName",
              "contentType",
              "cacheControl",
            ],
            uploadDataDuringCreation: true,
          });

        uppyRef.current.on("file-added", (file: any) => {
          if (!patientId || patientId === "") {
            window.alert("Patient ID is required for uploading files.");
            console.error("Patient ID is required for uploading files.");
            debugger;
          }
          const validFileName = generateValidSupabaseFileName(file.name);
          const supabaseMetadata = {
            bucketName: "docs",
            objectName: `${patientId}/${validFileName}`,
            contentType: file.type,
          };

          file.meta = {
            ...file.meta,
            ...supabaseMetadata,
          };

          console.log("file added", file);
        });

        uppyRef.current.on("upload-success", (file: any, response: any) => {
          console.log("Upload success:", file, response);
          onFileUploadSuccess(file, response);
        });

        uppyRef.current.on("complete", (result: any) => {
          console.log("Upload complete:", result.successful);
          onUploadComplete();
        });
      } catch (error) {
        console.error("Error initializing Uppy:", error);
      }
    };

    initializeUppy();

    return () => {
      if (uppyRef.current) {
        uppyRef.current.destroy(); // This is the correct way to clean up Uppy
      }
    };
  }, [patientId, category, onUploadComplete, onFileUploadSuccess]);

  const handleUpload = () => {
    if (uppyRef.current) {
      if (!patientId || patientId === "") {
        console.error("Patient ID is required for uploading files.");
        throw new Error("Patient ID is required for uploading files.");
      }
      uppyRef.current.upload();
    }
  };

  return (
    <Box>
      Patient ID : {patientId}
      <Box ref={dashboardRef}></Box>
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpload}
        sx={{ mt: 2 }}
      >
        Upload Files
      </Button>
    </Box>
  );
};

export default UppyUploader;
