// src/components/Patients.tsx
import React, { useEffect, useState } from 'react';
import supabase from '../../supabaseClient';
import { Patient } from '../../types';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, TextField, Button, Avatar, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import Banner from '../banner/Banner';
import './Patients.scss';

const Patients: React.FC = () => {
  const [patients, setPatients] = useState<Patient[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [viewMode, setViewMode] = useState<'grid' | 'cards'>('cards');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPatients = async () => {
      
      const { data, error } = await supabase.from('patient').select('*');
      if (error) console.error(error);
      else setPatients(data);
    };

    fetchPatients();
  }, []);

  useEffect(() => {
    const fetchPatients = async () => {
      let query = supabase.from('patient').select('*');
      if (searchTerm) {
        query = query
          .or(`firstName.ilike.%${searchTerm}%,lastName.ilike.%${searchTerm}%,mrn.ilike.%${searchTerm}%`);
      }
      const { data, error } = await query;
      if (error) {
        console.error(error);
      } else {
        setPatients(data);
      }
    };

    fetchPatients();
  }, [searchTerm]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleRowClick = (id: number) => {
    navigate(`/patients/${id}`);
  };

  const handleViewToggle = () => {
    setViewMode((prevMode) => (prevMode === 'cards' ? 'grid' : 'cards'));
  };

  const columns: GridColDef[] = [
    { field: 'mrn', headerName: 'MRN', width: 130 },
    { field: 'firstName', headerName: 'First Name', width: 130 },
    { field: 'lastName', headerName: 'Last Name', width: 130 },
    {
      field: 'dateOfBirth',
      headerName: 'Date of Birth',
      width: 200,
      valueFormatter: (params) => {
        return new Date(params as string).toLocaleDateString();
      },
    },
    { field: 'email', headerName: 'Email', width: 200 },
  ];

  const calculateAge = (dateOfBirth: string | undefined): number => {
    if (!dateOfBirth) return 0;
    const birthDate = new Date(dateOfBirth);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  return (
    <Box className="patients-container">
      <Banner
        icon={PersonIcon}
        title="Patients"
        subtitle={`You have total ${patients.length} patients registered in your clinic.`}
        buttons={<Button variant="contained" size="small" color="primary">Add Patient</Button>}
        controls={[
          <TextField
            key="search"
            label="Search Patients"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            size="small"
          />,
          <Button
            key="toggle-view"
            className="view-toggle-button"
            variant="contained"
            size="small"
            onClick={handleViewToggle}
          >
            Switch to {viewMode === 'cards' ? 'Grid' : 'Cards'} View
          </Button>,
        ]}
      />
      {viewMode === 'cards' ? (
        <Box className="patients-list" display="flex" flexWrap="wrap" gap="16px">
          {patients.map((patient) => (
            <Box
              key={patient.id}
              className="patient-item"
              onClick={() => handleRowClick(patient.id)}
              style={{ cursor: 'pointer', width: 'calc(33% - 16px)', padding: '16px', border: '1px solid #ddd', borderRadius: '8px' }}
            >
              <Avatar
                className="patient-avatar"
                src={patient.photo ? URL.createObjectURL(new Blob([patient.photo])) : ''}
                style={{ width: '56px', height: '56px' }}
              >
                {!patient.photo && (
                  <Typography variant="h4">
                    {patient.firstName.charAt(0)}
                    {patient.lastName.charAt(0)}
                  </Typography>
                )}
              </Avatar>
              <Box className="patient-info" style={{ marginTop: '16px' }}>
                <Typography variant="h6">
                  {patient.firstName} {patient.lastName}
                </Typography>
                <Typography>
                  {calculateAge(patient.dateOfBirth)} years old, {patient.gender}
                </Typography>
                <Typography>{patient.phoneNumber1}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <DataGrid
          rows={patients}
          columns={columns}
          onRowClick={(params) => handleRowClick(params.row.id as number)}
          style={{ width: '100%' }}
          getRowClassName={(params) => {
            return params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow';
          }}
        />
      )}
    </Box>
  );
};

export default Patients;
