import React, { useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import supabase from '../../supabaseClient';

interface PatientDocument {
  id: string;
  patient_id: number;
  file_name: string;
  file_key: string;
  file_type: string | null;
  category: string | null;
  file_size: number | null;
  created_at: string | null;
  updated_at: string | null;
  additional_metadata: any | null;
}

interface PatientDocumentsGridProps {
  patientId: number;
}

const PatientDocumentsGrid: React.FC<PatientDocumentsGridProps> = ({ patientId }) => {
  const [documents, setDocuments] = useState<PatientDocument[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedDocument, setSelectedDocument] = useState<PatientDocument | null>(null);
  
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    fetchDocuments();
  }, [patientId]);

  const fetchDocuments = async () => {
    setLoading(true);
    const { data, error } = await supabase 
      .from('patient_documents')
      .select('*')
      .eq('patient_id', patientId);
    if (error) {
      console.error('Error fetching documents:', error);
    } else {
      setDocuments(data || []);
    }
    setLoading(false);
  };

  const handleRowClick = (params: GridRowParams) => {
    const document = params.row as PatientDocument;
    setSelectedDocument(document);
  };

  const columns: GridColDef[] = [
    { field: 'file_name', headerName: 'File Name', width: 200, flex: 1 },
    { field: 'file_type', headerName: 'File Type', width: 120 },
    { field: 'category', headerName: 'Category', width: 150 },
    {
      field: 'file_size',
      headerName: 'Size',
      width: 120,
      valueFormatter: (params: any) => {
        if (params.value == null) return '';
        return `${(params.value / 1024 / 1024).toFixed(2)} MB`;
      },
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 200,
      valueFormatter: (params: any) => {
        if (params.value == null) return '';
        return new Date(params.value).toLocaleString();
      },
    },
  ];

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: isSmallScreen ? 'column' : 'row',
      gap: 2, 
      height: '100%',
      transition: 'all 0.3s ease-in-out'
    }}>
      <Box sx={{ 
        flex: 1,
        height: isSmallScreen ? 'auto' : '400px',
        width: '100%',
        minWidth: 0 // This ensures the box can shrink below its content size
      }}>
        <Typography variant="h6" gutterBottom>
          Patient Documents
        </Typography>
        <DataGrid
          rows={documents}
          columns={columns}
          loading={loading}
          autoHeight={isSmallScreen}
          onRowClick={handleRowClick}
          sx={{
            height: isSmallScreen ? 'auto' : '100%',
            '& .MuiDataGrid-main': { overflow: 'hidden' },
            '& .MuiDataGrid-columnHeaders': {
              position: 'sticky',
              top: 0,
              zIndex: 1,
            },
            '& .MuiDataGrid-virtualScroller': {
              overflow: 'auto !important',
            },
          }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 25]}
        />
      </Box>
      {selectedDocument && (
        <Box sx={{ 
          flex: 1,
          height: isSmallScreen ? 'auto' : '400px',
          overflow: 'auto', 
          backgroundColor: '#f0f0f0', 
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: 0 // This ensures the box can shrink below its content size
        }}>
          <Typography variant="h6" gutterBottom>
            Preview Panel
          </Typography>
          <Typography>
            Selected file: {selectedDocument.file_name}
          </Typography>
          <Box sx={{ 
            width: '80%', 
            height: '200px', 
            backgroundColor: '#ddd', 
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 2
          }}>
            <Typography>Preview Placeholder</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PatientDocumentsGrid;