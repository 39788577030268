// src/pages/SignInPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useSupabaseClient, useUser } from '@supabase/auth-helpers-react';
//import app.css
import './auth.css';
import { useUserContext } from '../contexts/UserContext';

const SignInPage = () => {
  const supabase = useSupabaseClient();
  const user = useUser();
  const navigate = useNavigate();
  const {setUserFacility} = useUserContext();

  // State variables for form inputs and feedback
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);


  // Fetch and store the facility value once the user is authenticated
  useEffect(() => {
    const fetchPrimaryFacility = async () => {
      if (user) {
        const { data, error } = await supabase
          .from('user_roles')  // adjust the table name as per your schema
          .select('primary_facility')
          .eq('user_id', user.id)
          .single();

        if (data) setUserFacility(data.primary_facility);
        if (error) console.error('Error fetching facility:', error);

        navigate('/dashboard');
      }
    };

    fetchPrimaryFacility();
  }, [user, navigate, supabase, setUserFacility]);


  // Redirect authenticated users to the dashboard
  useEffect(() => {
    if (user) {
    //user is already signed in, redirect to dashboard
    console.log('User is already signed in, redirecting to dashboard');
      navigate('/dashboard');
    }
  }, [user, navigate]);

  // Handle form submission for sign-in
  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    setLoading(false);

    if (error) {
      setError(error.message);
    } else {
      // Successful sign-in will trigger useUser and redirect via useEffect
      console.log('Sign-in successful');
    }
  };

  return (
    <div className="sign-in-page">
      <h2>Sign In</h2>
      <form onSubmit={handleSignIn}>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Enter your email"
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="Enter your password"
          />
        </div>
        {error && <p className="error">{error}</p>}
        <button type="submit" disabled={loading}>
          {loading ? 'Signing In...' : 'Sign In'}
        </button>
      </form>
      <p>
        Forgot your password? <Link to="/reset-password-request">Reset it here</Link>.
      </p>
    </div>
  );
};

export default SignInPage;
