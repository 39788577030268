import "devextreme/dist/css/dx.light.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import PatientDetails from "./components/patient/PatientDetails";
import ExaminationsPage from "./components/pages-patient/ExaminationsPage";
import MedicalAlertsPage from "./components/pages-patient/MedicalAlertsPage";
import FormsPage from "./components/pages/AssignedForms/FormsPage";
// import ConsentsPage from './components/pages-patient/ConsentsPage';
import DocumentsPage from "./components/pages-patient/DocumentsPage";
import RadiographsPage from "./components/pages-patient/RadiographsPage";
import PrescriptionsPage from "./components/pages-patient/PrescriptionsPage";
import CommunicationsPage from "./components/pages-patient/CommunicationsPage";
import AppointmentsPage from "./components/pages-patient/AppointmentsPage";
import TreatmentsPage from "./components/pages-patient/TreatmentsPage";
import LabworksPage from "./components/pages-patient/LabworksPage";
import AppConnectPage from "./components/pages-patient/AppConnectPage";
import PatientLayout from "./layouts/PatientLayout";
import AllPatientsPage from "./components/pages/AllPatientsPage";
import Header from "./components/header/Header";
import DashboardPage from "./components/pages/DashboardPage";
import Patients from "./components/patient/Patients";
import GeneralAssessmentForm from "./components/pages/GeneralAssessmentForm";
import AuthSelector from "./AuthSelector";
import ExtraOralExamForm from "./components/pages/ExtraOralExamForm";
import IntraOralExamForm from "./components/pages/IntraOralExamForm";
import AssignedFormTemplatesPage from "./components/pages/AssignedForms/AssignedFormTemplatesPage";
import AdminPage from "./components/pages/AdminPage";
import PatientDocsSamplePage from "./components/pages-patient/PatientDocsSamplePage";
import FileList from "./components/pages-patient/FileList";
import PatientDocumentsGrid from "./components/pages-patient/PatientDocumentsGrid";
import SimpleInteractivePerioChart from "./components/perio/SupabaseIntegratedPerioChart";
import SupabaseIntegratedPerioChart from "./components/perio/SupabaseIntegratedPerioChart";
import SchedulePage from "./components/pages/schedule/SchedulePage";
import { Schedule } from "@mui/icons-material";
//import devextreme global config
import config from "devextreme/core/config";
import UserProfilePage from "./components/user-profile/UserProfilePage";
import UserManagementPage from "./components/pages/Admin/UserManagementPage";
import AuthCallback from "./auth/AuthCallback";
import { SessionContextProvider } from "@supabase/auth-helpers-react";
import supabaseSingleton from "./supabaseClient";
import ResetPasswordRequest from "./auth/ResetPasswordRequest";
import ResetPassword from "./auth/ResetPassword";
import SignInPage from "./auth/SignInPage";
import ProtectedRoute from "./components/ProtectedRoute";
import { UserProvider } from "./contexts/UserContext";
import { licenseKey } from "./devextreme-license";


  //test env vars
const x = process.env.REACT_APP_SUPABASE_URL;
const y = process.env.REACT_APP_SUPABASE_ANON_KEY;
console.log(process.env.REACT_APP_SUPABASE_URL);
console.log(process.env.REACT_APP_SUPABASE_ANON_KEY);


//licenseKey
config({
  licenseKey: licenseKey,
})
function App() {
  return (
    <SessionContextProvider
      supabaseClient={supabaseSingleton}
      redirectTo="/signin"
    >      
    <UserProvider> {/* Ensure UserProvider wraps all components */}
      <Router>
        <Header />
        <Routes>
          {/* Public Routes */}
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/reset-password-request" element={<ResetPasswordRequest />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/auth/callback" element={<AuthCallback />} />
          <Route path="/auth-selector" element={<AuthSelector />} />

        {/* Protected Routes */}
        <Route
            path="/perio"
            element={
              <ProtectedRoute>
                <SupabaseIntegratedPerioChart
                  patientId="098dc159-688a-4673-8e30-d6afb4096dff"
                  encounterId="4a3ffc76-b5da-4a78-80e7-af049542c91a"
                />
              </ProtectedRoute>
            }
          />

          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <AdminPage />
              </ProtectedRoute>
            }
          />
          
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <DashboardPage />
              </ProtectedRoute>
            }
          />
          
          <Route
            path="/user-profile"
            element={
              <ProtectedRoute>
                <UserProfilePage />
              </ProtectedRoute>
            }
          />
          
          <Route
            path="/admin/form-templates"
            element={
              <ProtectedRoute>
                <AssignedFormTemplatesPage />
              </ProtectedRoute>
            }
          />
          
          <Route
            path="/schedule"
            element={
              <ProtectedRoute>
                <SchedulePage />
              </ProtectedRoute>
            }
          />
          
          <Route
            path="/filelist"
            element={
              <ProtectedRoute>
                <FileList />
              </ProtectedRoute>
            }
          />
          
          <Route
            path="/patients"
            element={
              <ProtectedRoute>
                <Patients />
              </ProtectedRoute>
            }
          />
          
          <Route
            path="/docgrid"
            element={
              <ProtectedRoute>
                <PatientDocumentsGrid patientId={1} />
              </ProtectedRoute>
            }
          />

          {/* Nested Protected Routes */}
          <Route
            path="/patients/:id"
            element={
              <ProtectedRoute>
                <PatientLayout />
              </ProtectedRoute>
            }
          >
            <Route index element={<PatientDetails />} />
            <Route path="examinations" element={<ExaminationsPage />} />
            <Route path="examinations/general-assessment" element={<GeneralAssessmentForm />} />
            <Route path="examinations/extra-oral" element={<ExtraOralExamForm />} />
            <Route path="examinations/intra-oral" element={<IntraOralExamForm />} />
            <Route path="medical-alerts" element={<MedicalAlertsPage />} />
            <Route path="forms" element={<FormsPage />} />
            <Route path="documents" element={<PatientDocsSamplePage />} />
            <Route path="radiographs" element={<RadiographsPage />} />
            <Route path="prescriptions" element={<PrescriptionsPage />} />
            <Route path="communications" element={<CommunicationsPage />} />
            <Route path="appointments" element={<AppointmentsPage />} />
            <Route path="treatments" element={<TreatmentsPage />} />
            <Route path="labworks" element={<LabworksPage />} />
            <Route path="appconnect" element={<AppConnectPage />} />
          </Route>

          <Route
            path="/admin/user-management"
            element={
              <ProtectedRoute>
                <UserManagementPage />
              </ProtectedRoute>
            }
          />

          {/* Testing Route (Ensure it's intended to be protected) */}
          <Route
            path="/perio-test"
            element={
              <ProtectedRoute>
                <SupabaseIntegratedPerioChart
                  patientId="098dc159-688a-4673-8e30-d6afb4096dff"
                  encounterId="4a3ffc76-b5da-4a78-80e7-af049542c91a"
                />
              </ProtectedRoute>
            }
          />

          {/* Catch-All Route */}
          {/* <Route path="*" element={<Navigate to="/dashboard" replace />} /> */}
     

        </Routes>
      </Router>
      </UserProvider>
    </SessionContextProvider>
  );
}

export default App;
