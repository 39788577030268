import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Box, Typography, Avatar } from '@mui/material';
import supabase from '../supabaseClient';
import { Patient } from '../types';
import './PatientLayout.scss';
import PatientNav from '../components/patient/PatientNav';

const PatientLayout: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [selectedSection, setSelectedSection] = useState<string>('Details');
  const [patient, setPatient] = useState<Patient | null>(null);

  useEffect(() => {
    const fetchPatient = async () => {
      const { data, error } = await supabase
        .from('patient')
        .select('*')
        .eq('id', id)
        .single();
      if (error) console.error(error);
      else setPatient(data);
    };

    fetchPatient();
  }, [id]);

  const calculateAge = (dob: string | undefined) => {
    if (!dob) return 'N/A';
    const birthDate = new Date(dob);
    const ageDifMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  return (
    <Box className="layout-container">
      <Box className="left-menu">
        <Box className="profile-summary">
          <Avatar className="profile-image" src={patient?.photo ? URL.createObjectURL(new Blob([patient.photo])) : ''}>
            {!patient?.photo && <Typography variant="h4">{patient?.firstName?.charAt(0)}{patient?.lastName?.charAt(0)}</Typography>}
          </Avatar>
          <Typography className="profile-name">{patient?.firstName} {patient?.lastName}</Typography>
          <Typography className="profile-info">{calculateAge(patient?.dateOfBirth)} years old, {patient?.gender}</Typography>
        </Box>
        <PatientNav selectedSection={selectedSection} setSelectedSection={setSelectedSection} />
      </Box>
      <Box className="main-content">
        <Outlet />
      </Box>
    </Box>
  );
};

export default PatientLayout;
