//configure dotenv
import { createClient } from "@supabase/supabase-js";
export const supabaseUrl = process.env.REACT_APP_SUPABASE_URL as string;
export const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY as string;

// export const supabaseKey = process.env.REACT_APP_SUPABASE_SERVICE_KEY as string;
// export const supabaseKey = process.env.REACT_APP_SUPABASE_SERVICE_KEY as string;
export const supabaseSingleton = createClient(supabaseUrl, supabaseKey);

// Optional: Function to get access token if needed elsewhere
export const getAccessToken = async () => {
    const { data: { session }, error: sessionError } = await supabaseSingleton.auth.getSession();
  
    if (sessionError) {
      console.error('Error fetching session:', sessionError);
      return '';
    }
  
    if (!session) {
      console.error('No active session found. User must be logged in.');
      return '';
    }
  
    return session.access_token;
  };


export default supabaseSingleton;