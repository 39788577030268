import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Box,
  Grid,
  Paper,
  useTheme,
  ToggleButtonGroup,
  ToggleButton,
  Button,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import FolderIcon from "@mui/icons-material/Folder";
import AddIcon from "@mui/icons-material/Add";
import ViewListIcon from "@mui/icons-material/ViewList";
import Banner from "../banner/Banner";
import "./PatientDocsSamplePage.scss";
import UppyUploader from "./UppyUploader";

const categories = [
  "Medical Records",
  "Lab Results",
  "Prescriptions",
  "Insurance",
  "Other",
];

// Sample data for the DataGrid
const sampleFiles = [
  {
    id: 1,
    name: "Blood Test Results.pdf",
    category: "Lab Results",
    uploadDate: "2024-08-01",
  },
  {
    id: 2,
    name: "Prescription-Aug2024.pdf",
    category: "Prescriptions",
    uploadDate: "2024-08-02",
  },
  {
    id: 3,
    name: "Insurance Claim Form.pdf",
    category: "Insurance",
    uploadDate: "2024-08-03",
  },
  {
    id: 4,
    name: "Annual Checkup Report.pdf",
    category: "Medical Records",
    uploadDate: "2024-08-04",
  },
];

const columns: GridColDef[] = [
  { field: "name", headerName: "File Name", flex: 1 },
  { field: "category", headerName: "Category", flex: 1 },
  { field: "uploadDate", headerName: "Upload Date", flex: 1 },
];

const PatientDocsSamplePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [mode, setMode] = useState<"add" | "view">("view");
  const theme = useTheme();

  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category);
  };

  const handleModeChange = (
    event: React.MouseEvent<HTMLElement>,
    newMode: "add" | "view" | null
  ) => {
    if (newMode !== null) {
      setMode(newMode);
    }
  };

  const handleUploadComplete = () => {
    console.log("Upload complete, refreshing file list...");
    // Implement file list refresh logic here
  };


  const renderCategoryPaper = (category: string) => {
    const isSelected = selectedCategory === category;
    return (
      <Paper
        elevation={3}
        className="category-paper"
        onClick={() => handleCategoryClick(category)}
        sx={{
          backgroundColor: isSelected
            ? theme.palette.primary.main
            : theme.palette.background.paper,
          color: isSelected
            ? theme.palette.primary.contrastText
            : theme.palette.text.primary,
          "&:hover": {
            backgroundColor: isSelected
              ? theme.palette.primary.dark
              : theme.palette.action.hover,
          },
        }}
      >
        <Typography variant="h6" className="category-text">
          {category}
        </Typography>
      </Paper>
    );
  };

  const renderAddMode = () => (
    <>
      <Grid container spacing={2} className="category-grid">
        {categories.map((category) => (
          <Grid item xs={12} sm={6} md={3} key={category}>
            {renderCategoryPaper(category)}
          </Grid>
        ))}
      </Grid>
      {selectedCategory && (
        <Box
          className="upload-section"
          sx={{ backgroundColor: theme.palette.background.paper, mt: 2 }}
        >
          <Typography variant="h6" gutterBottom color="primary">
            Upload {selectedCategory}
          </Typography>
          {id && 
            <UppyUploader
              patientId={id}
              category={selectedCategory}
              onUploadComplete={handleUploadComplete}
              onFileUploadSuccess={(file, response) => {
                console.log("File upload success:", file, response);
              }}
              />
          }
          
        </Box>
      )}
    </>
  );

  const renderViewMode = () => (
    <DataGrid
      rows={sampleFiles}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 5 },
        },
      }}
      pageSizeOptions={[5, 10]}
      checkboxSelection
      disableRowSelectionOnClick
    />
  );

  return (
    <Box className="patient-docs-sample-page">
      <Banner
        icon={FolderIcon}
        title="Patient Documents"
        subtitle="Upload and manage patient documents"
      />
      <Typography variant="h6" gutterBottom>
        Patient ID: {id}
      </Typography>

      <Box className="mode-toggle">
        <Typography variant="h5" color="primary">
          {mode === "add" ? "Add New Document" : "View Documents"}
        </Typography>
        <ToggleButtonGroup
          value={mode}
          exclusive
          onChange={handleModeChange}
          aria-label="text alignment"
        >
          <ToggleButton value="add" aria-label="add files">
            <AddIcon />
          </ToggleButton>
          <ToggleButton value="view" aria-label="view files">
            <ViewListIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {mode === "add" ? renderAddMode() : renderViewMode()}
    </Box>
  );
};

export default PatientDocsSamplePage;
