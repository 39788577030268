import React, { useEffect, useState } from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import { Link } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import EventIcon from "@mui/icons-material/Event";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import ScienceIcon from "@mui/icons-material/Science";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ChairIcon from "@mui/icons-material/Chair";
import GroupIcon from "@mui/icons-material/Group";
import Banner from "../banner/Banner";
import "./DashboardPage.scss";

const DashboardPage: React.FC = () => {
  const [data, setData] = useState({
    patients: 0,
    appointments: 0,
    treatments: 0,
    labWorks: 0,
    balances: 0,
    pendingTransactions: 0,
    chairs: 0,
    users: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/tempdata/mockData.json");
      const result = await response.json();
      setData(result);
    };

    fetchData();
  }, []);

  const formatNumber = (number: number) => {
    return new Intl.NumberFormat("en-US").format(number);
  };

  const formatCurrency = (number: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "SAR",
      minimumFractionDigits: 0,
    }).format(number);
  };

  const cards = [
    {
      title: "Patients",
      count: formatNumber(data.patients),
      description: "Total patients in your clinic",
      icon: <PersonIcon />,
      link: "/Patients",
    },
    {
      title: "Appointments",
      count: formatNumber(data.appointments),
      description: "All upcoming appointments",
      icon: <EventIcon />,
      link: "/Appointment",
    },
    {
      title: "Treatments",
      count: formatNumber(data.treatments),
      description: "Total planned/progress procedures",
      icon: <MedicalServicesIcon />,
      link: "/Treatment",
    },
    {
      title: "Lab Works",
      count: formatNumber(data.labWorks),
      description: "Total pending jobs",
      icon: <ScienceIcon />,
      link: "/Labwork/Manage/List",
    },
    {
      title: "Balances",
      count: formatCurrency(data.balances),
      description: "All patient pending amount",
      icon: <AccountBalanceIcon />,
      link: "/Accounts/Summary",
    },
    {
      title: "Pending Transactions",
      count: formatCurrency(data.pendingTransactions),
      description: "Pending in/out total amount",
      icon: <AccountBalanceWalletIcon />,
      link: "/Accounts/Summary",
    },
    {
      title: "Chairs",
      count: formatNumber(data.chairs),
      description: "Chairs in your clinic",
      icon: <ChairIcon />,
      link: "/Office/Chairs",
    },
    {
      title: "Users",
      count: formatNumber(data.users),
      description: "Users of your clinic",
      icon: <GroupIcon />,
      link: "/Office/Users",
    },
  ];

  return (
    <>
      <Box className="dashboard-page">
        <Banner
          icon={PersonIcon}
          title="Dashboard"
          subtitle="Overview of your clinic"
        />
        <Box className="dashboard-cards">
          {cards.map((card, index) => (
            <Link key={index} to={card.link} className="dashboard-card-link">
              <Card className="dashboard-card">
                <CardContent>
                  <Box className="card-header">
                    {card.icon}
                    <Typography variant="subtitle1">{card.title}</Typography>
                  </Box>
                  <Typography variant="h6" className="card-count">
                    {card.count}
                  </Typography>
                  <Typography variant="body2">{card.description}</Typography>
                </CardContent>
              </Card>
            </Link>
          ))}
        </Box>
      </Box>
      {/* <CurrentUser /> */}
    </>
  );
};

export default DashboardPage;
