import React, { useState, useEffect } from 'react';
import supabase from "../../supabaseClient";
import { Snackbar, Button } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { PostgrestError } from '@supabase/supabase-js';
import { set } from 'react-hook-form';
import { AnyARecord } from 'dns';


type ToothSection = {
  mobility?: number;
  implant?: boolean;
  furcation?: 'I' | 'II' | 'III';
  bleedingOnProbing?: [boolean, boolean, boolean];
  plaque?: [boolean, boolean, boolean];
  gingivalMargin?: [number, number, number];
  probingDepth?: [number, number, number];
  note?: string;
};

type PerioChartData = {
  [toothNumber: string]: {
    buccal?: ToothSection;
    palatal?: ToothSection;
  };
};

interface SupabaseIntegratedPerioChartProps {
  patientId: string; //uuid of the patient
  encounterId?: string; //uuid of the encounter
}
interface PerioChart {
  id: string;
  encounter_id: string;
  chart_data: any; // You might want to define a more specific type for chart_data
  created_at: string;
  updated_at: string;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SupabaseIntegratedPerioChart: React.FC<SupabaseIntegratedPerioChartProps> = ({ patientId, encounterId }) => {
  const teeth = [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28];
  const buccalMeasurements = ['Mobility', 'Implant', 'Furcation', 'Bleeding on Probing', 'Plaque', 'Gingival Margin', 'Probing Depth'];
  const palatalMeasurements = ['Gingival Margin', 'Probing Depth', 'Plaque', 'Bleeding on Probing', 'Furcation', 'Note'];

  const [chartData, setChartData] = useState<PerioChartData>({});
  const [toastMessage, setToastMessage] = useState<String | null>(null);
  const [loading, setLoading] = useState(true);
  const [toastOpen, setToastOpen] = useState(false);
  const [currentEncounterId, setCurrentEncounterId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [currentEncounteRecord, setCurrentEncounteRecord] = useState<any | null>(null);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  useEffect(() => {
    if (encounterId) {
      setCurrentEncounterId(encounterId);
      loadChartData(encounterId);
      loadEncounterRecord(encounterId);

    } else {
      loadTodaysEncounter();
    }
  }, [patientId, encounterId]);



//#region Database Operations
const loadTodaysEncounter = async () => {
  
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const { data, error } = await supabase
    .from('encounter')
    .select('id,encounterStartTime')
    .eq('patientId', patientId)
    //.gte('encounter_date', today.toISOString())
    .gte('encounterStartTime', new Date(today.getTime() - 24*60*60*1000).toISOString()) //TODO: change to 24 hours
    .lt('encounter_date', new Date(today.getTime() + 24*60*60*1000).toISOString()) //TODO: change to 24 hours
    .order('encounterStartTime', { ascending: false })
    .limit(1)
    .single();

  if (error) {
    if (error.code === 'PGRST116') {
      // No encounter found for today, create a new one
      await createNewEncounter();
    
      alert('No encounter found for today, create a new one');

    } else {
      console.error('Error loading today\'s encounter:', error);
      setError('Failed to load today\'s encounter');
    }
  } else if (data) {
    setCurrentEncounterId(data.id);
    loadChartData(data.id);

    setToastMessage("Today's Encounter loaded successfully");
    setToastOpen(true);
  }
};

const createNewEncounter = async () => {
  const { data, error } = await supabase
    .from('encounter')
    .insert({ patientId: patientId })
    .select()
    .single();

  if (error) {
    console.error('Error creating new encounter:', error);
    setError('Failed to create new encounter');
  } else if (data) {
    setCurrentEncounterId(data.id);
    // Initialize with empty chart data
    setChartData({});
    setLoading(false);

    //show toast message that new item was created
    setToastMessage('New encounter created successfully');
    setToastOpen(true);
  }
};

//load the encounter record from the database table clinical_encounter
const loadEncounterRecord = async (eid: string) => {
  //loads the encounter record from the database table clinical_encounter
  const { data, error } = await supabase
    .from('clinical_encounter')
    .select('*')
    .eq('id', eid)
    .single();

  if (error) {
    console.error('Error loading encounter record:', error);
    setError('Failed to load encounter record');
  }
  else if (data) {
    setCurrentEncounteRecord(data);
  }
}

const loadChartData = async (eid: string) => {
  setLoading(true);
  const { data, error } = await supabase
    .from('perio_charts')
    .select('chart_data')
    .eq('encounter_id', eid)
    .single();

  if (error) {
    if (error.code === 'PGRST116') {
      // No chart data found, initialize with empty data
      setChartData({});
    } else {
      console.error('Error loading chart data:', error);
      setError('Failed to load chart data');
    }
  } else if (data) {
    setChartData(data.chart_data);
  }
  setLoading(false);
};
//#endregion

  const saveChartData = async () => {
    const encounterId = '4a3ffc76-b5da-4a78-80e7-af049542c91a';

    const { data, error } = await supabase
      .from('perio_charts')
      .upsert(
        {
          encounter_id: encounterId,
          chart_data: chartData,
        },
        {
          onConflict: 'encounter_id',
        }
      );
  
    if (error) {
      console.error('Error saving chart data:', error);
    } else {
      console.log('Chart data saved successfully');
      setToastMessage('Chart data saved successfully');
      setToastOpen(true);
    }
  };

  const handleCellClick = (tooth: number, measurement: string, section: 'buccal' | 'palatal', index: number | null = null) => {
    setChartData(prevData => {
      const newData: PerioChartData = { ...prevData };
      if (!newData[tooth]) newData[tooth] = {};
      if (!newData[tooth][section]) newData[tooth][section] = {};

      const currentSection = newData[tooth][section] as ToothSection;

      switch (measurement) {
        case 'Mobility':
          currentSection.mobility = ((currentSection.mobility || 0) + 1) % 4;
          break;
        case 'Implant':
          currentSection.implant = !currentSection.implant;
          break;
        case 'Furcation':
          const furcationValues: ('I' | 'II' | 'III' | undefined)[] = [undefined, 'I', 'II', 'III'];
          currentSection.furcation = furcationValues[(furcationValues.indexOf(currentSection.furcation) + 1) % furcationValues.length];
          break;
        case 'Bleeding on Probing':
        case 'Plaque':
          const key = measurement === 'Bleeding on Probing' ? 'bleedingOnProbing' : 'plaque';
          if (!currentSection[key]) currentSection[key] = [false, false, false];
          if (index !== null) currentSection[key]![index] = !currentSection[key]![index];
          break;
        case 'Gingival Margin':
        case 'Probing Depth':
          const depthKey = measurement === 'Gingival Margin' ? 'gingivalMargin' : 'probingDepth';
          if (!currentSection[depthKey]) currentSection[depthKey] = [0, 0, 0];
          if (index !== null) currentSection[depthKey]![index] = (currentSection[depthKey]![index] + 1) % 10;
          break;
        case 'Note':
          const noteInput = prompt('Enter note:', currentSection.note || '');
          if(noteInput !== null){
            currentSection.note = noteInput;
          }
          break;
      }

      return newData;
    });
  };

  const renderCell = (tooth: number, measurement: string, section: 'buccal' | 'palatal') => {
    const toothData = chartData[tooth]?.[section];
    
    const renderSubCell = (index: number) => {
      let content: string | number = '-';
      let style = {};

      switch (measurement) {
        case 'Bleeding on Probing':
          content = toothData?.bleedingOnProbing?.[index] ? 'B' : '-';
          style = toothData?.bleedingOnProbing?.[index] ? { backgroundColor: 'lightpink' } : {};
          break;
        case 'Plaque':
          content = toothData?.plaque?.[index] ? 'P' : '-';
          style = toothData?.plaque?.[index] ? { backgroundColor: 'lightblue' } : {};
          break;
        case 'Gingival Margin':
          content = toothData?.gingivalMargin?.[index] ?? '-';
          break;
        case 'Probing Depth':
          content = toothData?.probingDepth?.[index] ?? '-';
          break;
      }

      return (
        <div
          key={`${tooth}-${section}-${measurement}-${index}`}
          onClick={() => handleCellClick(tooth, measurement, section, index)}
          style={{
            cursor: 'pointer',
            padding: '2px',
            border: '1px solid #ddd',
            display: 'inline-block',
            width: '20px',
            textAlign: 'center',
            ...style
          }}
          title={`${measurement} for tooth ${tooth} (${section}) - Position ${index + 1}`}
        >
          {content}
        </div>
      );
    };

    if (['Bleeding on Probing', 'Plaque', 'Gingival Margin', 'Probing Depth'].includes(measurement)) {
      return (
        <td key={`${tooth}-${section}-${measurement}`} style={{ padding: '2px', border: '1px solid #ccc' }}>
          {[0, 1, 2].map(renderSubCell)}
        </td>
      );
    }

    let content: string | number = '-';
    switch (measurement) {
      case 'Mobility':
        content = toothData?.mobility ?? '-';
        break;
      case 'Implant':
        content = toothData?.implant ? '■' : '□';
        break;
      case 'Furcation':
        content = toothData?.furcation ?? '-';
        break;
      case 'Note':
        content = toothData?.note ? '📝' : '-';
        break;
    }

    return (
      <td
        key={`${tooth}-${section}-${measurement}`}
        onClick={() => handleCellClick(tooth, measurement, section)}
        style={{
          cursor: 'pointer',
          padding: '5px',
          border: '1px solid #ccc',
          textAlign: 'center',
        }}
        title={`${measurement} for tooth ${tooth} (${section})`}
      >
        {content}
      </td>
    );
  };

  const renderToothPlaceholder = (section: 'buccal' | 'palatal') => (
    <tr key={`placeholder-${section}`}>
      <th key={`placeholder-header-${section}`} style={{ textAlign: 'left', padding: '5px', border: '1px solid #ccc' }}>
        {section.charAt(0).toUpperCase() + section.slice(1)}
      </th>
      {teeth.map(tooth => (
        <td key={`placeholder-${section}-${tooth}`} style={{ padding: '5px', border: '1px solid #ccc' }}>
          <div style={{ width: '60px', height: '40px', backgroundColor: '#f0f0f0' }}></div>
        </td>
      ))}
    </tr>
  );

  if (loading) {
    return <div>Loading chart data...</div>;
  }

  return (
    <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
      <h2 style={{ textAlign: 'center', margin: '20px 0' }}>Periodontal Chart - Patient #{patientId}</h2>
      {/* TODO: Remove */}
      <h3>Encounter:
      <span>{currentEncounteRecord && currentEncounteRecord.encounter_date && new Date(currentEncounteRecord.encounter_date).toLocaleDateString()}</span>
      </h3>
      <span>{currentEncounterId}</span>
      
      <table style={{ borderCollapse: 'collapse', margin: '0 auto' }}>
        <thead>
          <tr>
            <th key="header-empty"></th>
            {teeth.map(tooth => (
              <th key={`header-${tooth}`} style={{ padding: '5px', border: '1px solid #ccc' }}>{tooth}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {buccalMeasurements.map(measurement => (
            <tr key={`buccal-${measurement}`}>
              <th key={`buccal-header-${measurement}`} style={{ textAlign: 'left', padding: '5px', border: '1px solid #ccc' }}>{measurement}</th>
              {teeth.map(tooth => (
                <React.Fragment key={`buccal-${measurement}-${tooth}`}>
                  {renderCell(tooth, measurement, 'buccal')}
                </React.Fragment>
              ))}
            </tr>
          ))}
          {renderToothPlaceholder('buccal')}
          {renderToothPlaceholder('palatal')}
          {palatalMeasurements.map(measurement => (
            <tr key={`palatal-${measurement}`}>
              <th key={`palatal-header-${measurement}`} style={{ textAlign: 'left', padding: '5px', border: '1px solid #ccc' }}>{measurement}</th>
              {teeth.map(tooth => (
                <React.Fragment key={`palatal-${measurement}-${tooth}`}>
                  {renderCell(tooth, measurement, 'palatal')}
                </React.Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <button
          onClick={saveChartData}
          style={{
            padding: '10px 20px',
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
           
          }}
        >
          Save Chart
        </button>
          <button 
            onClick={() => loadChartData(currentEncounterId!)}
            style={{
              padding: '10px 20px',
              backgroundColor: '#2196F3',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              marginLeft: '10px'
            }}
          >
            Reload Chart
        </button>
        <button
          onClick={() => setChartData({})}
          style={{
            padding: '10px 20px',
            backgroundColor: '#f44336',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            marginLeft: '10px'
          }}
        >
          Clear Chart
        </button>
      </div>
      <Snackbar open={toastOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SupabaseIntegratedPerioChart;