// src/theme.ts
import { createTheme, ThemeOptions } from '@mui/material/styles';

const getTheme = (mode: 'light' | 'dark'): ThemeOptions => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // Light mode colors
          primary: { main: '#1976d2' },
          secondary: { main: '#dc004e' },
        }
      : {
          // Dark mode colors
          primary: { main: '#90caf9' },
          secondary: { main: '#f48fb1' },
          background: {
            default: '#121212',
            paper: '#1e1e1e',
          },
        }),
  },
});

// const theme = createTheme(getTheme('dark')); 

// or 'light'
const theme = createTheme(getTheme('light')); // or 'light'

export default theme;