import React from 'react';
import Banner from '../banner/Banner';
import ImageIcon from '@mui/icons-material/Image';

const RadiographsPage: React.FC = () => {
  return (
    <div>
      <Banner
        icon={ImageIcon}
        title="Radiographs"
        subtitle="View and manage patient radiographs"
      />
    </div>
  );
};

export default RadiographsPage;
