import React from 'react';
import Banner from '../banner/Banner';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';

const AppConnectPage: React.FC = () => {
  return (
    <div>
      <Banner
        icon={ConnectWithoutContactIcon}
        title="AppConnect"
        subtitle="Connect with external applications"
      />
    </div>
  );
};

export default AppConnectPage;
