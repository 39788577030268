import React from 'react';
import { Box, Typography, RadioGroup, FormControlLabel, Radio } from '@mui/material';

interface IntraOralLandmark {
  id: string;
  name: string;
}

interface IntraOralDiagramProps {
  currentLandmark: string;
  onLandmarkSelect: (landmarkId: string) => void;
  landmarks: IntraOralLandmark[];
}

const IntraOralDiagram: React.FC<IntraOralDiagramProps> = ({ currentLandmark, onLandmarkSelect, landmarks }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onLandmarkSelect(event.target.value);
  };

  return (
    <Box
      sx={{
        width: '300px',
        border: '1px solid #ccc',
        padding: '16px',
        borderRadius: '8px',
      }}
    >
      {/* <Typography variant="h6" align="center" gutterBottom>
        Intra-Oral Diagram
      </Typography> */}
      <Box
        sx={{
          width: '200px',
          height: '300px',
          margin: '0 auto 16px',
          borderRadius: '8px',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <img 
          src="/images/intraoral.jpeg" 
          alt="Intra-Oral Diagram Placeholder" 
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </Box>
      <Box
        sx={{
          maxHeight: '200px',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
          },
        }}
      >
        <RadioGroup
          aria-label="intra-oral-landmarks"
          name="intra-oral-landmarks"
          value={currentLandmark}
          onChange={handleChange}
        >
          {landmarks.map((landmark) => (
            <FormControlLabel
              key={landmark.id}
              value={landmark.id}
              control={<Radio size="small" />}
              label={<Typography variant="body2">{landmark.name}</Typography>}
              sx={{ marginY: 0.5 }}
            />
          ))}
        </RadioGroup>
      </Box>
    </Box>
  );
};

export default IntraOralDiagram;