import React, { useState, useEffect } from 'react';
import Banner from '../banner/Banner';
import WarningIcon from '@mui/icons-material/Warning';
import { TextField, Checkbox, FormGroup, FormControlLabel, Typography, Paper, Box, Snackbar } from '@mui/material';
import "./PatientPageCommon.scss";
import supabase from '../../supabaseClient';
import { useParams } from 'react-router-dom';

interface AlertItem {
  category: string;
  title: string;
  checked: boolean;
}

const MedicalAlertsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [alerts, setAlerts] = useState<AlertItem[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    const fetchAlerts = async () => {
      if (!id) return;

      try {
        const patientId = parseInt(id);
        
        // Fetch all available alerts from medalerts.json
        const response = await fetch('/tempdata/medalerts.json');
        const allAlerts: AlertItem[] = await response.json();

        // Check if a record exists for this patient
        const { data: existingRecord, error: checkError } = await supabase
          .from('MedicalAlerts')
          .select('medicalAlerts')
          .eq('patientId', patientId)
          .single();

        let patientAlerts: AlertItem[];

        if (checkError && checkError.code === 'PGRST116') {
          // No record exists, all alerts are unchecked
          patientAlerts = allAlerts.map(alert => ({ ...alert, checked: false }));
        } else if (checkError) {
          throw checkError;
        } else {
          // Record exists, merge with all alerts
          patientAlerts = allAlerts.map(alert => ({
            ...alert,
            checked: existingRecord.medicalAlerts.some((existingAlert: AlertItem) => 
              existingAlert.category === alert.category && existingAlert.title === alert.title
            )
          }));
        }

        setAlerts(patientAlerts);
      } catch (error) {
        console.error('Error fetching alerts:', error);
        setSnackbarMessage("Error loading medical alerts");
        setSnackbarOpen(true);
      }
    };

    fetchAlerts();
  }, [id]);

  const handleAlertChange = async (index: number) => {
    if (!id) return;
  
    const newAlerts = [...alerts];
    newAlerts[index].checked = !newAlerts[index].checked;
    setAlerts(newAlerts);
  
    const patientId = parseInt(id);
    const checkedAlerts = newAlerts.filter(alert => alert.checked).map(({ category, title }) => ({ category, title }));
    
    try {
      // First, check if a record exists for this patient
      const { data, error: checkError } = await supabase
        .from('MedicalAlerts')
        .select('patientId')
        .eq('patientId', patientId)
        .single();
  
      if (checkError && checkError.code !== 'PGRST116') {
        throw checkError;
      }
  
      let result;
      if (checkedAlerts.length === 0) {
        // If no alerts are checked, delete the record if it exists
        if (data) {
          result = await supabase
            .from('MedicalAlerts')
            .delete()
            .eq('patientId', patientId);
        }
      } else if (!data) {
        // If the record doesn't exist and we have checked alerts, insert a new record
        result = await supabase
          .from('MedicalAlerts')
          .insert({ patientId, medicalAlerts: checkedAlerts });
      } else {
        // If the record exists and we have checked alerts, update it
        result = await supabase
          .from('MedicalAlerts')
          .update({ medicalAlerts: checkedAlerts })
          .eq('patientId', patientId);
      }
  
      if (result && result.error) throw result.error;
      
      setSnackbarMessage("Medical alerts updated successfully");
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error saving alerts to Supabase:', error);
      setSnackbarMessage("Error updating medical alerts");
      setSnackbarOpen(true);
    }
  };

  const categories = Array.from(new Set(alerts.map(alert => alert.category)));

  return (
    <div className="patient-page-container">
      {/* ... (Banner and other UI components remain the same) ... */}
      <Box sx={{ 
        flexGrow: 1, 
        padding: 3, 
        overflowY: 'auto', 
        maxHeight: 'calc(100vh - 200px)',
        columnCount: {xs: 1, sm: 2, md: 3, lg: 4},
        columnGap: '16px'
      }}>
        {categories.map((category) => (
          <Paper 
            key={category}
            elevation={3} 
            sx={{ 
              p: 2, 
              mb: 2,
              breakInside: 'avoid',
              pageBreakInside: 'avoid',
              WebkitColumnBreakInside: 'avoid',
              display: 'inline-block',
              width: '100%'
            }}
          >
            <Typography variant="h6" gutterBottom>{category}</Typography>
            <FormGroup>
              {alerts
                .filter(alert => alert.category === category)
                .map((alert) => (
                  <FormControlLabel
                    key={alert.title}
                    control={
                      <Checkbox
                        checked={alert.checked}
                        onChange={() => handleAlertChange(alerts.findIndex(a => a.category === alert.category && a.title === alert.title))}
                      />
                    }
                    label={alert.title}
                  />
                ))}
            </FormGroup>
          </Paper>
        ))}
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </div>
  );
};

export default MedicalAlertsPage;