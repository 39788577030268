import React from 'react';
import Banner from '../banner/Banner';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const AppointmentsPage: React.FC = () => {
  return (
    <div>
      <Banner
        icon={CalendarTodayIcon}
        title="Appointments"
        subtitle="View and manage patient appointments"
      />
    </div>
  );
};

export default AppointmentsPage;
