export function generateValidSupabaseFileName(fileName: string): string {
    // Step 1: Decode URI components to handle potential URL-encoded characters
    let validName = decodeURIComponent(fileName);
  
    // Step 2: Replace spaces and unwanted characters with underscores
    validName = validName.replace(/[^a-zA-Z0-9.-]/g, '_');
  
    // Step 3: Remove consecutive underscores
    validName = validName.replace(/_+/g, '_');
  
    // Step 4: Ensure the file name doesn't start or end with an underscore or dot
    validName = validName.replace(/^[_.-]+|[_.-]+$/g, '');
  
    // Step 5: Limit the length to 255 characters (including extension)
    if (validName.length > 255) {
      const extension = validName.split('.').pop() || '';
      const nameWithoutExtension = validName.slice(0, -(extension.length + 1));
      validName = nameWithoutExtension.slice(0, 255 - (extension.length + 1)) + '.' + extension;
    }
  
    // Step 6: If the name is empty after processing, use a default name
    if (validName === '' || validName === '.') {
      validName = 'file';
    }
  
    // Step 7: Add a timestamp to ensure uniqueness
    const timestamp = Date.now();
    const extension = validName.includes('.') ? validName.split('.').pop() : '';
    const nameWithoutExtension = extension ? validName.slice(0, -(extension.length + 1)) : validName;
    
    validName = `${nameWithoutExtension}_${timestamp}${extension ? '.' + extension : ''}`;
  
    return validName;
  }