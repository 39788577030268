// src/components/banner/Banner.tsx
import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import './Banner.scss';

interface BannerProps {
  icon: SvgIconComponent;
  title: string;
  subtitle: string;
  buttons?: React.ReactNode;
  controls?: React.ReactNode[];
}

const Banner: React.FC<BannerProps> = ({ icon: Icon, title, subtitle, buttons, controls = [] }) => {
  return (
    <Box className="banner-container">
      <Box className="banner-content">
        <Box className="header-left">
          <Icon className="header-icon" />
          <Box className="header-info">
            <Typography className="header-title">{title}</Typography>
            <Typography className="header-subtitle">{subtitle}</Typography>
          </Box>
        </Box>
        <Box className="header-right">
          {controls && controls.length > 0 && (
            <Box className="banner-controls">
              {controls.map((control, index) => (
                <Box key={index} className="banner-control">
                  {control}
                </Box>
              ))}
            </Box>
          )}
          {controls.length > 0 && buttons && <Divider orientation="vertical" flexItem className="banner-divider" />}
          {buttons && (
            <Box className="banner-actions">
              {buttons}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Banner;