import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Typography, Divider } from '@mui/material';
import supabase from '../../supabaseClient';
import Banner from '../banner/Banner';
import PersonIcon from '@mui/icons-material/Person';
import PatientDetailsSection from './PatientDetailsSection';
import ExaminationsSection from '../exams/ExaminationsSection';
import { Patient, Examination, GeneralAssessmentExam, OralExam } from '../../types';
import './PatientDetails.scss';

const PatientDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [selectedSection, setSelectedSection] = useState<string>('Details');
  const [patient, setPatient] = useState<Patient | null>(null);
  const [examinations, setExaminations] = useState<Examination[]>([]);

  useEffect(() => {
    const fetchPatient = async () => {
      const { data, error } = await supabase
        .from('patient')
        .select('*')
        .eq('id', id)
        .single();
      if (error) console.error(error);
      else setPatient(data);
    };

    fetchPatient();
  }, [id]);

  useEffect(() => {
    const fetchExaminations = async () => {
      //will need to fetch from each type of exam table: GeneralAssessmentExam, OralExam, IntraOralExam

      let { data: genAssessmentData, error : gaerror } = await supabase
        .from('general_assessment_exam')
        .select('*')
        .eq('patientId', id);
      if (gaerror) console.error(gaerror);

      const generalAssessmentExams = genAssessmentData as GeneralAssessmentExam[];

      let {data: oralExamData, error: oeerror} = await supabase
        .from('oral_exam')
        .select('*')
        .eq('patientId', id);
      if (oeerror) console.error(oeerror);

      const oralExams = oralExamData as OralExam[];

      //get the data from IntraOralExam table
      let {data: intraOralExamData, error: ioeerror} = await supabase
        .from('intra_oral_exam')
        .select('*')
        .eq('patientId', id);

      //combine the data from all three tables using the common fields: createdDate, modifiedDate, createdById, modifiedById
      //set the exam-type field to the table name

      const allExams = [];
      generalAssessmentExams.forEach(exam => {
        allExams.push({
          id: exam.id,
          date: exam.createdDate,
          type: 'General Assessment',
          filledBy: exam.createdById
        });
      });

      oralExams.forEach(exam => {
        allExams.push({
          id: exam.id,
          date: exam.createdDate,
          type: 'Oral Exam',
          filledBy: exam.createdById
        });
      });



    };

    fetchExaminations();
  }, [id]);

  const renderSection = () => {
    switch (selectedSection) {
      case 'Details':
        return <PatientDetailsSection patient={patient} />;
      case 'Examinations':
        return <ExaminationsSection examinations={examinations} />;
      // Add cases for other sections like Medical Alerts, etc.
      default:
        return <Typography>Select a section</Typography>;
    }
  };

  return (
    <Box className="main-panel">
      <Banner
        icon={PersonIcon}
        title="Patient Details"
        subtitle="View and update the patient details"
        buttons={selectedSection === 'Details' && (
          <>
            <Button variant="contained" color="primary">Edit</Button>
            <Button variant="contained" color="secondary">Picture</Button>
            <Button variant="contained" color="error">Remove</Button>
          </>
        )}
      />
      <Divider />
      {renderSection()}
    </Box>
  );
};

export default PatientDetails;
