import React, { useState, useEffect } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardContent,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Alert,
  Typography,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import supabaseSingleton from "../../../supabaseClient";


const UserManagementPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [roles, setRoles] = useState([]);
  const [facilities, setFacilities] = useState([]);

  // Form states
  const [formData, setFormData] = useState({
    // Auth User Details
    email: "",
    password: "",
    confirmPassword: "",

    // Application User Details
    firstName: "",
    lastName: "",

    // Clinician Details
    isClinicianUser: false,
    clinicianType: "",
    facilityIdPrimary: "",

    // Role Details
    selectedRoles: [],
  });

  // Fetch roles and facilities on mount
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        // Fetch roles
        const { data: rolesData, error: rolesError } = await supabaseSingleton
          .from("application_role")
          .select("id, name, description");

        if (rolesError) throw rolesError; 
        setRoles(rolesData);

        // Fetch facilities
        const { data: facilitiesData, error: facilitiesError } = await supabaseSingleton
          .from("facility")
          .select("id, name");

        if (facilitiesError) throw facilitiesError;
        setFacilities(facilitiesData);
      } catch (err) {
        setError("Error loading initial data: " + err.message);
      }
    };

    fetchInitialData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: e.target.type === "checkbox" ? checked : value,
    }));
  };

  const handleRoleChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      selectedRoles: event.target.value,
    }));
  };

  const validateStep = () => {
    setError("");

    switch (activeStep) {
      case 0: // Auth User Creation
        if (
          !formData.email ||
          !formData.password ||
          !formData.confirmPassword
        ) {
          setError("All fields are required");
          return false;
        }
        if (formData.password !== formData.confirmPassword) {
          setError("Passwords do not match");
          return false;
        }
        if (formData.password.length < 6) {
          setError("Password must be at least 6 characters");
          return false;
        }
        return true;

      case 1: // Application User Details
        if (!formData.firstName || !formData.lastName) {
          setError("First and last name are required");
          return false;
        }
        return true;

      case 2: // Clinician Details
        if (formData.isClinicianUser) {
          if (!formData.clinicianType || !formData.facilityIdPrimary) {
            setError("All clinician fields are required");
            return false;
          }
        }
        return true;

      case 3: // Role Assignment
        if (formData.selectedRoles.length === 0) {
          setError("Please select at least one role");
          return false;
        }
        return true;

      default:
        return true;
    }
  };

  const handleNext = async () => {
    if (!validateStep()) return;

    if (activeStep === 3) {
      await handleSubmit();
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };
  const handleClear = () => {
    if(!window.confirm("Are you sure you want to clear the form?")) {
      return;
    }
    setActiveStep(0);
    setFormData({
      email: "",
      password: "testingPassword123",
      confirmPassword: "testingPassword123",
      firstName: "",
      lastName: "",
    })
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      // 1. Create auth user
      const { data: authData, error: authError } = await supabaseSingleton.auth.signUp({
        email: formData.email,
        password: formData.password,
        options: {
          emailRedirectTo: `${window.location.origin}/auth/callback`,
          data: {
            firstName: formData.firstName,
            lastName: formData.lastName,
            pending_application_user: true,
          },
        },
      });

      // Check the response
      if (authData.user?.identities?.length === 0) {
        throw new Error("Email already taken");
      }

      // Check if email confirmation is required
      if (authData.user && !authData.user.confirmed_at) {
        setSuccess(
          "Please check your email for verification link. User records will be created after email verification."
        );
        return;
      }

      // If somehow email confirmation is not required, proceed with user creation
      await createApplicationUser(authData.user); 
     
      //now, clear the form data in case the page is revisited for another user creation
      setFormData({});

      // Could reset form here if needed
    } catch (err) {
      setError("Error creating user: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  // Separate function to create application user
  const createApplicationUser = async (authUser) => {
    try {
      // 2. Create application user
      const { data: appUser, error: appUserError } = await supabaseSingleton
        .from("application_user")
        .insert({
          authUid: authUser.id,
          email: formData.email,
          firstName: formData.firstName,
          lastName: formData.lastName,
        })
        .select()
        .single();

      if (appUserError) throw appUserError;

      // 3. Create clinician if selected
      if (formData.isClinicianUser) {
        const { data: clinician, error: clinicianError } = await supabaseSingleton
          .from("clinician")
          .insert({
            facilityIdPrimary: formData.facilityIdPrimary,
            clinicianType: formData.clinicianType,
            email: formData.email,
            firstName: formData.firstName,
            lastName: formData.lastName,
          })
          .select()
          .single();

        if (clinicianError) throw clinicianError;

        // Update application user with clinician ID
        await supabaseSingleton
          .from("application_user")
          .update({ clinicianId: clinician.id })
          .eq("id", appUser.id);
      }

      // 4. Assign roles
      const roleAssignments = formData.selectedRoles.map((roleId) => ({
        userId: appUser.id,
        roleId,
      }));

      const { error: rolesError } = await supabaseSingleton
        .from("application_user_role")
        .insert(roleAssignments);

      if (rolesError) throw rolesError;

      setSuccess("User records created successfully!");
    } catch (err) {
      throw err;
    }
  };

  const GetStepNextButtonText = (stepNumber) => {
    switch (stepNumber) {
      case 0:
        return "Next";
      case 1:
        return "Next";
      case 2:
        return "Next";
      case 3:
        return "Create User";
      case 4:
        return "Submitted";
      default:
        return "Next";
    }
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleInputChange}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              value={formData.confirmPassword}
              onChange={handleInputChange}
            />
          </Box>
        );

      case 1:
        return (
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
            />
          </Box>
        );

      case 2:
        return (
          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.isClinicianUser}
                  onChange={handleInputChange}
                  name="isClinicianUser"
                />
              }
              label="Create as Clinician"
              sx={{ mb: 2 }}
            />

            {formData.isClinicianUser && (
              <>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>Clinician Type</InputLabel>
                  <Select
                    name="clinicianType"
                    value={formData.clinicianType}
                    onChange={handleInputChange}
                    label="Clinician Type"
                  >
                    <MenuItem value="GP">GP</MenuItem>
                    <MenuItem value="Consultant">Consultant</MenuItem>
                    <MenuItem value="SeniorRegistrar">
                      Senior Registrar
                    </MenuItem>
                    <MenuItem value="Registrar">Registrar</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel>Primary Facility</InputLabel>
                  <Select
                    name="facilityIdPrimary"
                    value={formData.facilityIdPrimary}
                    onChange={handleInputChange}
                    label="Primary Facility"
                  >
                    {facilities.map((facility) => (
                      <MenuItem key={facility.id} value={facility.id}>
                        {facility.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
          </Box>
        );

      case 3:
        return (
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Assign Roles</InputLabel>
              <Select
                multiple
                value={formData.selectedRoles}
                onChange={handleRoleChange}
                label="Assign Roles"
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((roleId) => {
                      const role = roles.find((r) => r.id === roleId);
                      return role ? (
                        <Typography key={roleId} component="span">
                          {role.name}
                        </Typography>
                      ) : null;
                    })}
                  </Box>
                )}
              >
                {roles.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                    <Typography
                      variant="caption"
                      sx={{ ml: 1, color: "text.secondary" }}
                    >
                      ({role.description})
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <Box sx={{ maxWidth: 800, margin: "auto", mt: 4, px: 2 }}>
      <Card elevation={2}>
        <CardContent>
          <Typography variant="h4" sx={{ mb: 4 }}>
            Create New User
          </Typography>

          <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
            <Step>
              <StepLabel>Auth Details</StepLabel>
            </Step>
            <Step>
              <StepLabel>User Details</StepLabel>
            </Step>
            <Step>
              <StepLabel>Clinician Details</StepLabel>
            </Step>
            <Step>
              <StepLabel>Role Assignment</StepLabel>
            </Step>
            {/* Final Step - Pending Email Verification */}
            <Step>
              <StepLabel>Submitted</StepLabel>
            </Step>
          </Stepper>

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          {success && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {success}
            </Alert>
          )}

          {renderStepContent()}

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
            <Button onClick={handleBack} disabled={activeStep === 0 || loading}>
              Back
            </Button>
            <Button variant="contained" onClick={handleNext} disabled={loading}>
              {loading ? (
                <CircularProgress size={24} />
              ) : GetStepNextButtonText(activeStep)
              }
            </Button>
          </Box>
          
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
            <Button onClick={handleClear} disabled={loading}>
              Clear Form
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default UserManagementPage;
