import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Modal, Grid } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Banner from '../banner/Banner';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import { useNavigate } from 'react-router-dom';
import './ExaminationsPage.scss';
import './PatientPageCommon.scss';

const ExaminationsPage: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleNavigation = (path: string) => {
    navigate(path);
    handleClose();
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'date', headerName: 'Date', width: 200 },
    { field: 'type', headerName: 'Type', width: 200 },
    { field: 'filledBy', headerName: 'Filled By', width: 200 },
  ];

  const rows: readonly any[] = []; // Placeholder for rows data

  return (
    <Box className="patient-page-container" sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Banner
        icon={MedicalServicesIcon}
        title="Examinations"
        subtitle="View and manage patient examinations"
        controls={[
          <TextField
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ flexGrow: 1, maxWidth: '300px' }}
          />,
        ]}
      />
      
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, p: 2, overflow: 'auto' }}>
        <Box sx={{ mb: 2 }}>
          <Button variant="contained" color="primary" onClick={handleOpen}>
            New
          </Button>
        </Box>
        <Box sx={{ overflowX: 'auto', width: '100%' }}>
          <Box sx={{ width: '800px', maxWidth: '100%' }}> {/* Fixed width container */}
            <DataGrid
              rows={rows}
              columns={columns}
              checkboxSelection
              autoHeight
              sx={{
                '& .MuiDataGrid-main': { overflow: 'hidden' },
                '& .MuiDataGrid-columnHeaders': { 
                  position: 'sticky', 
                  top: 0, 
                  zIndex: 1 
                },
                '& .MuiDataGrid-virtualScroller': { 
                  overflow: 'auto !important' 
                },
              }}
            />
          </Box>
        </Box>
      </Box>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
        <Box className="modal-container" sx={{ 
          position: 'absolute', 
          top: '50%', 
          left: '50%', 
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          maxWidth: '90%',
          maxHeight: '90%',
          overflow: 'auto'
        }}>
          <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
            Examinations
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Button variant="contained" fullWidth onClick={() => handleNavigation('./general-assessment')}>
                General Assessment
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button variant="contained" fullWidth onClick={() => handleNavigation('./extra-oral')}>
                Extra Oral
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button variant="contained" fullWidth onClick={() => handleNavigation('./intra-oral')}>
                Intra Oral
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    
    </Box>
  );
};

export default ExaminationsPage;