import React from 'react';
import { Modal, Box, Button, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { styled } from '@mui/system';

interface NewExaminationModalProps {
  open: boolean;
  handleClose: () => void;
  handleSelect: (type: string) => void;
}

const StyledButton = styled(Button)({
  border: '1px solid #ccc',
  borderRadius: '8px',
  padding: '8px 16px',
  margin: '8px 0',
  textTransform: 'none',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  '&:hover': {
    backgroundColor: '#f0f0f0',
  },
  '& .MuiListItemIcon-root': {
    minWidth: '40px',
  },
});

const NewExaminationModal: React.FC<NewExaminationModalProps> = ({ open, handleClose, handleSelect }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Examinations
        </Typography>
        <List>
          <ListItem>
            <StyledButton onClick={() => handleSelect('General Assessment')}>
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary="General Assessment" />
            </StyledButton>
          </ListItem>
          <ListItem>
            <StyledButton onClick={() => handleSelect('Extra Oral')}>
              <ListItemIcon>
                <VisibilityIcon />
              </ListItemIcon>
              <ListItemText primary="Extra Oral" />
            </StyledButton>
          </ListItem>
          <ListItem>
            <StyledButton onClick={() => handleSelect('Intra Oral')}>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Intra Oral" />
            </StyledButton>
          </ListItem>
        </List>
      </Box>
    </Modal>
  );
};

export default NewExaminationModal;
