// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '@supabase/auth-helpers-react';

const ProtectedRoute = ({ children }) => {
  const user = useUser();

  if (!user) {
    // User is not authenticated
    return <Navigate to="/signin" replace />;
  }

  // User is authenticated
  return children;
};

export default ProtectedRoute;
