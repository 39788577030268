import React from 'react';
import Banner from '../banner/Banner';
import ChatIcon from '@mui/icons-material/Chat';

const CommunicationsPage: React.FC = () => {
  return (
    <div>
      <Banner
        icon={ChatIcon}
        title="Communications"
        subtitle="View and manage patient communications"
      />
    </div>
  );
};

export default CommunicationsPage;
