// src/pages/ResetPassword.js
import React, { useState, useEffect } from 'react';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import './auth.css'; 

const ResetPassword = () => {
  const supabase = useSupabaseClient();
  const navigate = useNavigate();
  const location = useLocation();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [tokenType, setTokenType] = useState(null);
  const [resetComplete, setResetComplete] = useState(false); // New state flag
  
  // Extract access_token once on component mount
  useEffect(() => {
    // If accessToken is already set, no need to re-process
    if (accessToken) return;
  
    const hash = location.hash.substring(1); // Remove '#'
    const params = new URLSearchParams(hash);
    const token = params.get('access_token');
    const type = params.get('type');
  
    console.log('URL Hash:', location.hash);
    console.log('Extracted Token:', token);
    console.log('Token Type:', type);
  
    setTokenType(type);
  
    if (token) {
      setAccessToken(token);
      setError(''); // Clear any previous errors
  
      // Remove the hash from the URL to clean it up
      window.history.replaceState(null, '', location.pathname);
    } else if (!accessToken) {
      // Only set the error if accessToken is not already set
      setError('Invalid or missing access token.');
    }
  }, [accessToken, location]);
  

  const handleReset = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    // Basic validations
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    if (password.length < 8) {
      setError('Password must be at least 8 characters long.');
      return;
    }

    if (!accessToken) {
      setError('Cannot reset password without a valid access token.');
      return;
    }

    setLoading(true);

    try {
      const { error } = await supabase.auth.updateUser(
        { password },
        { access_token: accessToken }
      );

      if (error) {
        console.error('Password Reset Error:', error);
        setError(error.message);
      } else {
        setMessage('Password has been reset successfully! Redirecting to Sign In...');
        setResetComplete(true);
        navigate('/signin'); // Immediate redirect
      }
    } catch (err) {
      console.error('Unexpected Error:', err);
      setError('An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="reset-password">
      <h2>Set New Password</h2>

      {/* Diagnostic Information */}
      <div className="diagnostics" style={{ backgroundColor: '#f9f9f9', padding: '10px', marginBottom: '20px', border: '1px solid #ddd' }}>
        <h4>Diagnostics:</h4>
        <p><strong>Current URL:</strong> {window.location.href}</p>
        <p><strong>Access Token:</strong> {accessToken ? accessToken : 'None'}</p>
        <p><strong>Token Type:</strong> {tokenType ? tokenType : 'None'}</p>
        <p><strong>Error:</strong> {error}</p>
      </div>

      {/* Display Success Message */}
      {message && <p className="success">{message}</p>}

      {/* Display Form Only If Access Token Exists and Reset Not Complete */}
      {true || accessToken && !resetComplete ? (
        <form onSubmit={handleReset}>
          <div>
            <label>New Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Enter new password"
            />
          </div>
          <div>
            <label>Confirm New Password:</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              placeholder="Confirm new password"
            />
          </div>
          <button type="submit" disabled={loading}>
            {loading ? 'Resetting...' : 'Reset Password'}
          </button>
        </form>
      ) : !resetComplete ? (
        <p>Please request a password reset <Link to="/reset-password-request">here</Link>.</p>
      ) : null}

      <p>
        Back to <Link to="/signin">Sign In</Link>
      </p>
    </div>
  );
};

export default ResetPassword;
