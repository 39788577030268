import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Box, Card, CardContent, Avatar } from '@mui/material';
import supabaseSingleton from './supabaseClient';


const testPassword = `testingPassword123`;
//read test users from a csv file that has name, email, and password


function AuthSelector() {
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();
  const [userRoles, setUserRoles] = useState([]);
  const [applicationUsers, setApplicationUsers] = useState([]);

  useEffect(() => {
    checkCurrentUser();
    loadPageData();

  }, []);

  const loadPageData= async () => {
    //load users from application_user table, add where isTestUser = true
    let usersData = await supabaseSingleton.from('application_user')
    .select('id, email, firstName, lastName')
    .eq('isTestUser', true);
    // usersData = usersData.data;
    setApplicationUsers(usersData.data);
  }

  const checkCurrentUser = async () => {
    const { data: { user } } = await supabaseSingleton.auth.getUser();
    if (user) {
      // debugger;
      setCurrentUser(user);
    } else {
      setCurrentUser(null);
    }
  };




  const handleUserSelect = async (user) => {
    try {
      await supabaseSingleton.auth.signOut(); // Sign out current user if any
      const { data, error } = await supabaseSingleton.auth.signInWithPassword({
        email: user.email,
        password: testPassword,
      });
      if (error) throw error;

      setCurrentUser(data.user);

      // Fetch the application user along with their roles
      const { data: applicationUserData, error: applicationUserError } = await supabaseSingleton
        .from('application_user')
        .select(`
          id,
          UserRole:application_user_role (
            role:application_role (id, name)
          )
        `)
        .eq('authUid', data.user.id)
        .single();

      if (applicationUserError) throw applicationUserError;

      const roles = applicationUserData.UserRole.map(ur => ur.role.name);
      setUserRoles(roles);

      // Store roles in localStorage
      localStorage.setItem('userRoles', JSON.stringify(roles));

      // Update user profile with additional information
      // const { error: updateError } = await supabaseSingleton.auth.updateUser({
      //   data: {
      //     firstName: user.name.split(' ')[0],
      //     lastName: user.name.split(' ')[1] || '',
      //     roles: roles
      //   }
      // });

      // if (updateError) throw updateError;

      console.log('User authenticated and roles loaded:', roles);
    } catch (error) {
      console.error('Error signing in:', error.message);
    }
  };



  const handleLogOff = async () => {
    try {
      await supabaseSingleton.auth.signOut();
      setCurrentUser(null);
      localStorage.removeItem('userRole');
      console.log('Logged off successfully');
    } catch (error) {
      console.error('Error logging off:', error.message);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4, p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Select a user to impersonate
      </Typography>
      {applicationUsers.map((user) => (
        <Card key={user.email} sx={{ mb: 2, cursor: 'pointer' }} onClick={() => handleUserSelect(user)}>
          <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar sx={{ mr: 2 }}>{user.firstName[0]}</Avatar>
            <Box>
              <Typography variant="h6">{user.firstName}</Typography>
              <Typography variant="body2" color="text.secondary">
                {user.email}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ))}
      {currentUser && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1">
            Current user: {currentUser.email}
          </Typography>
          <h4>Roles:</h4>
          <ul>
            {userRoles.map((role, index) => (
              <li key={index}>{role}</li>
            ))}
          </ul>
          <Button
            variant="contained"
            color="secondary"
            sx={{ mt: 1 }}
            onClick={handleLogOff}
          >
            Log Off
          </Button>
        </Box>
      )}
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => navigate('/dashboard')}
      >
        Go to Dashboard
      </Button>
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => navigate('/user-profile')}
      >
        Go to User Profile Page
      </Button>
      {!currentUser && (
        <Typography variant="body1" sx={{ mt: 2 }}>
          Currently in anonymous context
        </Typography>
      )}
    </Box>
  );
}

export default AuthSelector;