import React from 'react';
import { Box, Typography, Grid, Card, CardContent, Paper } from '@mui/material';

interface DetailSectionProps {
  title: string;
  details: { label: string; value: string | number | null | undefined }[];
}

const DetailSection: React.FC<DetailSectionProps> = ({ title, details }) => {
  return (
    <Box className="patient-info">
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            {title}
          </Typography>
          <Grid container spacing={3}>
            {details.map((detail, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Paper elevation={0} className="field">
                  <Typography variant="subtitle1" color="textSecondary">{detail.label}</Typography>
                  <Typography variant="body1">{detail.value || 'N/A'}</Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default DetailSection;
