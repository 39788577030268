// services/userManagement.ts

import supabaseSingleton from "../supabaseClient";


interface UserCreationData {
  email: string;
  firstName: string;
  lastName: string;
  isClinicianUser?: boolean;
  clinicianType?: string;
  facilityIdPrimary?: string;
  selectedRoles: string[];
}

export const createApplicationUser = async (authUser: any, userData: UserCreationData) => {
  try {
    // 1. Create application user
    const { data: appUser, error: appUserError } = await supabaseSingleton
      .from('application_user')
      .insert({
        authUid: authUser.id,
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
      })
      .select()
      .single();

    if (appUserError) throw appUserError;

    // 2. Create clinician if selected
    if (userData.isClinicianUser) {
      const { data: clinician, error: clinicianError } = await supabaseSingleton
        .from('clinician')
        .insert({
          facilityIdPrimary: userData.facilityIdPrimary,
          clinicianType: userData.clinicianType,
          email: userData.email,
          firstName: userData.firstName,
          lastName: userData.lastName,
        })
        .select()
        .single();

      if (clinicianError) throw clinicianError;

      // Update application user with clinician ID
      const { error: updateError } = await supabaseSingleton
        .from('application_user')
        .update({ clinicianId: clinician.id })
        .eq('id', appUser.id);

      if (updateError) throw updateError;
    }

    // 3. Assign roles
    const roleAssignments = userData.selectedRoles.map(roleId => ({
      userId: appUser.id,
      roleId
    }));

    const { error: rolesError } = await supabaseSingleton
      .from('application_user_role')
      .insert(roleAssignments);

    if (rolesError) throw rolesError;

    return { appUser, success: true };
  } catch (err) {
    console.error('Error creating application user:', err);
    throw err;
  }
};

// Add any other user management related functions here