import React from 'react';
import { Box, Typography, Button, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import Banner from '../banner/Banner';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import BarChartIcon from '@mui/icons-material/BarChart';
import SecurityIcon from '@mui/icons-material/Security';
import './AdminPage.scss';
import { useNavigate } from 'react-router-dom';

const AdminPage: React.FC = () => {
  const navigate = useNavigate();

  const adminFunctions = [
    { title: 'Patient-Assigned-Form Templates', icon: <AssignmentIcon />, path: '/admin/form-templates' },
    { title: 'User Management', icon: <PeopleIcon />, path: '/admin/user-management' },
    { title: 'System Settings', icon: <SettingsIcon />, path: '/admin/settings' },
    { title: 'Analytics Dashboard', icon: <BarChartIcon />, path: '/admin/analytics' },
    { title: 'Security and Permissions', icon: <SecurityIcon />, path: '/admin/security' },
  ];

  return (
    <Box className="admin-page">
      <Banner
        icon={AdminPanelSettingsIcon}
        title="Admin Dashboard"
        subtitle="Manage your clinic's administrative functions"
        buttons={<Button variant="contained" color="primary">Generate Report</Button>}
      />
      <Box className="admin-functions-list">
        <List>
          {adminFunctions.map((func, index) => (
            <ListItem 
              key={index} 
              button 
              onClick={() => navigate(func.path)}
              className="admin-function-item"
            >
              <ListItemIcon>{func.icon}</ListItemIcon>
              <ListItemText primary={func.title} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default AdminPage;