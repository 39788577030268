// components/auth/AuthCallback.tsx
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import { createApplicationUser } from '../services/UserManagement';
import supabase from '../supabaseClient';

const AuthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState();
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    const handleAuthCallback = async () => {
      try {
  
        //first check if there's an error_description in the URL, this means the user denied the request
        const urlParams = new URLSearchParams(location.hash.substring(1));
        const errorDescription = urlParams.get('error_description');
        if (errorDescription) {
          //show error message, by settting the error state
          setError(errorDescription);
          return;
        }
        // Get #access_token and #refresh_token from URL fragment
        const hashParams = new URLSearchParams(location.hash.substring(1));
        const accessToken = hashParams.get('access_token');
        const refreshToken = hashParams.get('refresh_token');

        if (!accessToken) {
          throw new Error('No access token found in URL');
        }

        // Set the session using the tokens
        const { data: { session }, error: sessionError } = await supabase.auth.setSession({
          access_token: accessToken,
          refresh_token: refreshToken || '',
        });

        if (sessionError) throw sessionError;

        // Get the user
        const { data: { user }, error: userError } = await supabase.auth.getUser();
        
        if (userError) throw userError;

        if (!user) {
          throw new Error('No user found');
        }

        // Check if we need to create application user
        if (user.user_metadata?.pending_application_user) {
          // Extract user data from metadata
          const userData = {
            email: user.email || '',
            firstName: user.user_metadata.firstName,
            lastName: user.user_metadata.lastName,
            isClinicianUser: user.user_metadata.isClinicianUser,
            clinicianType: user.user_metadata.clinicianType,
            facilityIdPrimary: user.user_metadata.facilityIdPrimary,
            selectedRoles: user.user_metadata.selectedRoles,
          };

          await createApplicationUser(user, userData);
          
          // Clear the pending flag
          await supabase.auth.updateUser({
            data: { pending_application_user: false }
          });
        } 

        // Redirect to dashboard
        navigate('/dashboard');
        //don't just redirect to the dashboard unless the user just signed in
        //check if the user just signed in

      } catch (err) {
        console.error('Error in auth callback:', err);
        // setError(err instanceof Error ? err.message : 'An error occurred during authentication');
        // Optionally redirect to error page after a delay
        
        // setTimeout(() => navigate('/auth/error'), 3000);
      } finally {
        setIsProcessing(false);
      }
    };

    handleAuthCallback();
  }, [navigate, location.hash]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '80vh',
        gap: 3,
        px: 2
      }}
    >
      {isProcessing ? (
        <>
          <Typography variant="h5" component="h2" gutterBottom>
            Completing Your Registration
          </Typography>
          <CircularProgress />
          <Typography color="text.secondary">
            Please wait while we process your verification...
          </Typography>
        </>
      ) : error ? (
        <Alert severity="error" sx={{ maxWidth: 400 }}>
          {error}
        </Alert>
      ) : null}
    </Box>
  );
};

export default AuthCallback;