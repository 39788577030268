import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import WarningIcon from '@mui/icons-material/Warning';
import DescriptionIcon from '@mui/icons-material/Description';
// import ConsentIcon from '@mui/icons-material/AssignmentTurnedIn';
import FolderIcon from '@mui/icons-material/Folder';
import ImageIcon from '@mui/icons-material/Image';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import ChatIcon from '@mui/icons-material/Chat';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import HealingIcon from '@mui/icons-material/Healing';
import BiotechIcon from '@mui/icons-material/Biotech';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import './PatientNav.scss';

interface PatientNavProps {
  selectedSection: string;
  setSelectedSection: (section: string) => void;
}

const PatientNav: React.FC<PatientNavProps> = ({ selectedSection, setSelectedSection }) => {
  const { id } = useParams<{ id: string }>();

  const sections = [
    { label: 'Details', icon: <InfoIcon />, path: `/patients/${id}` },
    { label: 'Examinations', icon: <MedicalServicesIcon />, path: `/patients/${id}/examinations` },
    { label: 'Medical Alerts', icon: <WarningIcon />, path: `/patients/${id}/medical-alerts` },
    { label: 'Forms', icon: <DescriptionIcon />, path: `/patients/${id}/forms` },
    // { label: 'Consents', icon: <ConsentIcon />, path: `/patients/${id}/consents` },
    { label: 'Documents', icon: <FolderIcon />, path: `/patients/${id}/documents` },
    { label: 'Radiographs', icon: <ImageIcon />, path: `/patients/${id}/radiographs` },
    { label: 'Prescription', icon: <LocalPharmacyIcon />, path: `/patients/${id}/prescriptions` },
    { label: 'Communications', icon: <ChatIcon />, path: `/patients/${id}/communications` },
    { label: 'Appointments', icon: <CalendarTodayIcon />, path: `/patients/${id}/appointments` },
    { label: 'Treatments', icon: <HealingIcon />, path: `/patients/${id}/treatments` },
    { label: 'Labworks', icon: <BiotechIcon />, path: `/patients/${id}/labworks` },
    { label: 'AppConnect', icon: <ConnectWithoutContactIcon />, path: `/patients/${id}/appconnect` },
  ];

  return (
    <List component="nav" className="patient-nav">
      {sections.map((section, index) => (
        <React.Fragment key={section.label}>
          <ListItem
            button
            component={Link}
            to={section.path}
            selected={selectedSection === section.label}
            onClick={() => setSelectedSection(section.label)}
            className="patient-nav-item"
          >
            <ListItemIcon className="list-item-icon">{section.icon}</ListItemIcon>
            <ListItemText primary={section.label} />
          </ListItem>
          {section.label === 'Communications' && <Divider className="custom-divider" />}
        </React.Fragment>
      ))}
    </List>
  );
};

export default PatientNav;
