import React, { createContext, useState, useEffect, useContext } from 'react';
import { useSupabaseClient, useUser } from '@supabase/auth-helpers-react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userFacility, setUserFacility] = useState(null);
    const supabase = useSupabaseClient();
    const user = useUser();  // Get the authenticated user

    useEffect(() => {
        const fetchPrimaryFacility = async () => {
            if (user && !userFacility) {  // Ensure the user exists and facility isn't set yet
                const { data, error } = await supabase
                    .from('application_user')  // Main table for users
                    .select(`
                        primaryFacilityId,
                        facility (
                            id,
                            name
                        )
                    `)
                    .eq('authUid', user.id)  // Match the authenticated user's ID to 'authUid'
                    .single();
 
                if (data) {
                    // Store the facility object or only the name as needed
                    setUserFacility(data.facility);  
                } else if (error) {
                    console.error('Error fetching facility:', error);
                }
            }
        };

        fetchPrimaryFacility();
    }, [user, supabase, userFacility]);  // Dependencies ensure it runs on initial load

    return (
        <UserContext.Provider value={{ userFacility, setUserFacility }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUserContext = () => useContext(UserContext);
