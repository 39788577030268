// src/components/GeneralAssessmentForm.tsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, TextField, Typography, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import Banner from '../banner/Banner';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import supabase from '../../supabaseClient';
import './ExamForm.scss';
import { useNavigate } from 'react-router-dom';
const GeneralAssessmentForm: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [formData, setFormData] = useState({
    mentalOrientation: '',
    physicalAppearance: '',
    stature: '',
    posture: '',
    gait: '',
    communication: '',
    pulse: '',
    systolicBP: '',
    diastolicBP: '',
    respiration: '',
    temperature: '',
  });

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch patient data if needed
  }, [id]);

  // when cancel button is clicked, navigate to the examinations path
  const handleCancel = () => {
    // navigate to the examinations path
    navigate("../examinations")
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      debugger
      if(!id){
        throw new Error('Patient ID is required');
      }
      const { data, error } = await supabase
        .from('GeneralAssessmentExam')
        .insert([{ patientId: parseInt(id), formData }]);

      if (error) {
        throw error;
      } else {
        alert('Form submitted successfully!');
        setFormData({
          mentalOrientation: '',
          physicalAppearance: '',
          stature: '',
          posture: '',
          gait: '',
          communication: '',
          pulse: '',
          systolicBP: '',
          diastolicBP: '',
          respiration: '',
          temperature: '',
        });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred while submitting the form');
    }
  };

  return (
    <Box className="general-assessment-form">
       <Banner
        icon={MedicalServicesIcon}
        title="Examination - General Assessment"
        subtitle={`Fill in the form below to complete the general assessment examination`}
        controls={[
          <Box key="form-buttons" display="flex" justifyContent="flex-end" alignItems="center">
            <Button type="button" variant="outlined" sx={{ mr: 1 }} onClick={()=>{handleCancel()}}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary" onClick={()=>handleSubmit()}>
              Submit
            </Button>
          </Box>,
        ]}
      />
      <form>
        <Box display="flex" flexDirection="column" gap="16px" p="16px">
          <Typography variant="h6">Mental Orientation</Typography>
          <RadioGroup name="mentalOrientation" value={formData.mentalOrientation ?? ''} onChange={handleRadioChange} row>
            <FormControlLabel value="Normal" control={<Radio />} label="Normal" />
            <FormControlLabel value="Alert" control={<Radio />} label="Alert" />
            <FormControlLabel value="Fearful" control={<Radio />} label="Fearful" />
            <FormControlLabel value="Anxious" control={<Radio />} label="Anxious" />
            <FormControlLabel value="Suspicious" control={<Radio />} label="Suspicious" />
            <FormControlLabel value="Distressed" control={<Radio />} label="Distressed" />
          </RadioGroup>

          <Typography variant="h6">Physical Appearance</Typography>
          <RadioGroup name="physicalAppearance" value={formData.physicalAppearance} onChange={handleRadioChange} row>
            <FormControlLabel value="Normal" control={<Radio />} label="Normal" />
            <FormControlLabel value="Other" control={<Radio />} label="Other" />
          </RadioGroup>

          <TextField label="Stature" name="stature" value={formData.stature} onChange={handleChange} fullWidth />
          <TextField label="Posture" name="posture" value={formData.posture} onChange={handleChange} fullWidth />
          <TextField label="Gait" name="gait" value={formData.gait} onChange={handleChange} fullWidth />
          <TextField label="Communication" name="communication" value={formData.communication} onChange={handleChange} fullWidth />
          <TextField label="Pulse (Range 60-100, Average 72)" name="pulse" value={formData.pulse} onChange={handleChange} fullWidth />
          
          <Typography variant="h6">Blood Pressure</Typography>
          <TextField label="Systolic (Normal range : 60 to 80)" name="systolicBP" value={formData.systolicBP} onChange={handleChange} fullWidth />
          <TextField label="Diastolic (Normal range : 90 to 120)" name="diastolicBP" value={formData.diastolicBP} onChange={handleChange} fullWidth />
          <TextField label="Respiration (Normal range 12-15)" name="respiration" value={formData.respiration} onChange={handleChange} fullWidth />
          <TextField label="Temperature (Normal - 37 degree Celsius)" name="temperature" value={formData.temperature} onChange={handleChange} fullWidth />

          <Box display="flex" justifyContent="space-between">
            <Button type="button" variant="outlined">Cancel</Button>
            <Button type="submit" variant="contained" color="primary">Submit</Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default GeneralAssessmentForm;
