import { Alert, Snackbar, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import { useParams } from "react-router-dom";
import supabase from "../../../supabaseClient";
import { debug } from "console";
import 'devextreme/dist/css/dx.light.css';
import Scheduler, { Resource } from 'devextreme-react/scheduler';

interface Appointment {
  id: string;
  title: string;
  startDate: Date;
  endDate: Date;
  resourceId: number;
  patientId: string;
  clinicianName: string;
}

const SchedulePage: React.FC = () => {
  const [mode, setMode] = useState<"default" | "tabs">("default");
  // const [allAppointments, setAllAppointments] = useState<any[]>([]);
  const [appointments, setAppointments] = useState<Appointment[]>([]);


  const { id } = useParams<{ id: string }>();
  const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: 'success' | 'error' }>({ 
    open: false, 
    message: '', 
    severity: 'success' 
  });

  
  const resources: any = [
    {
      fieldExpr: 'resourceId',
      dataSource: [
        { id: 1, text: 'Jerry Garcia', type: 'dentist', color: '#58ab2d' },
        { id: 2, text: 'Mary Jane', type: 'hygienist', color: '#a001a2' },
      ],
      label: 'Assigned To',
    },
  ];

  useEffect(() => {
    loadAppointments();
  }, []);

  const loadAppointments = async () => {
    const { data, error } = await supabase
      .from('appointment')
      .select(`
        *,
        patient:patientId (firstName, lastName),
        clinician:clinicianId (firstName, lastName)
      `);

    if (error) {
      console.error('Error loading appointments:', error);
    } else {
      const formattedAppointments = data.map((appointment) => ({
        id: appointment.id,
        title: `${appointment.patient.lastName}, ${appointment.patient.firstName}`,
        startDate: new Date(appointment.appointmentDateTime),
        endDate: new Date(appointment.appointmentEndTime),
        resourceId: appointment.clinicianId,
        patientId: appointment.patientId,
        clinicianName: 'empty'//`${appointment.clinician.firstName} ${appointment.clinician.lastName}`,
      }));
      setAppointments(formattedAppointments);
    }
  };
  const onAppointmentCreated = async (e: any) => {
    const newAppointment = e.appointmentData;
    try {
      const startUTC = new Date(newAppointment.startDate.getTime() - newAppointment.startDate.getTimezoneOffset() * 60000);
      const endUTC = new Date(newAppointment.endDate.getTime() - newAppointment.endDate.getTimezoneOffset() * 60000);
  
      const { error } = await supabase
        .from('appointment')
        .insert({
          appointmentDateTime: startUTC.toISOString(),
          appointmentEndTime: endUTC.toISOString(),
          clinicianId: '896eb6a1-7039-4d2c-8a22-a31c7037f102', //hard code for testing newAppointment.resourceId,
          patientId: '16f2e441-1e41-4ac9-a46e-b62435b0dd98', //hard code for testing newAppointment.patientId,
          title: newAppointment.title,  // Add other fields as needed
        });
  
      if (error) throw error;
  
      console.log('Appointment created successfully');
      loadAppointments(); // Reload appointments to include the new one
      setSnackbar({ open: true, message: 'Appointment created successfully', severity: 'success' });
    } catch (error) {
      console.error('Error creating appointment:', error);
      setSnackbar({ open: true, message: 'Error creating appointment', severity: 'error' });
    }
  };

  
  const onAppointmentUpdated = async (e: any) => {
    const updatedAppointment = e.appointmentData;
    try {
      const startUTC = new Date(updatedAppointment.startDate.getTime() - updatedAppointment.startDate.getTimezoneOffset() * 60000);
      const endUTC = new Date(updatedAppointment.endDate.getTime() - updatedAppointment.endDate.getTimezoneOffset() * 60000);

      const { error } = await supabase
        .from('appointment')
        .update({
          appointmentDateTime: startUTC.toISOString(),
          appointmentEndTime: endUTC.toISOString(),
          // clinicianId: updatedAppointment.resourceId,
        })
        .eq('id', updatedAppointment.id);

      if (error) throw error;

      console.log('Appointment updated successfully');
      loadAppointments(); // Reload appointments to reflect changes
      setSnackbar({ open: true, message: 'Appointment updated successfully', severity: 'success' });
    } catch (error) {
      console.error('Error updating appointment:', error);
      setSnackbar({ open: true, message: 'Error updating appointment', severity: 'error' });
    }
  };

  const appointmentTooltipRender = (model: any) => {
    const appointmentData = model.appointmentData;
    return (
      <div>
        <Typography variant="subtitle1">
          <PersonRoundedIcon />
          {appointmentData.clinicianName} - {resources[0].dataSource.find((r: any) => r.id === appointmentData.resourceId)?.type}
        </Typography>
        <Typography variant="body2">
          Patient: {appointmentData.title}
        </Typography>
      </div>
    );
  };

  return (
    <>
      <Scheduler
      dataSource={appointments}
      defaultCurrentView="week"
      startDayHour={9}
      endDayHour={19}
      height={600}
      onAppointmentAdded={onAppointmentCreated}
      onAppointmentUpdated={onAppointmentUpdated}
      appointmentTooltipRender={appointmentTooltipRender}
    >
      <Resource
        dataSource={resources[0].dataSource}
        fieldExpr="resourceId"
        label="Assigned To"
      />
    </Scheduler>

      <Snackbar 
        
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert  onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} 
        sx={{ width: '100%', padding: '16px', fontSize: '1.2rem' }}>
          {snackbar.message}

        </Alert>
      </Snackbar>

    </>

    
  );
}
      

export default SchedulePage;