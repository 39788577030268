import React from 'react';
import './AssignedFormResults.scss';

interface FormData {
  [key: string]: string;
}

interface AssignedFormResultsProps {
  data: FormData;
}

const AssignedFormResults: React.FC<AssignedFormResultsProps> = ({ data }) => {
  return (
    <div className="assigned-form-results">
      {Object.entries(data).map(([key, value]) => (
        <div key={key} className="row">
          <span className="question">{formatKey(key)}:</span>
          <span className="answer">{value}</span>
        </div>
      ))}
    </div>
  );
};

const formatKey = (key: string): string => {
  return key.split(/(?=[A-Z])/).join(' ').replace(/^\w/, c => c.toUpperCase());
};

export default AssignedFormResults;