import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import supabase from "../../../supabaseClient";
import Banner from "../../banner/Banner";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Snackbar, Alert } from "@mui/material";
// import './AssignedFormsPage.scss';

interface AssignedForm {
  id: string;
  name: string;
  description: string;
  form_schema: any;
  created_at: string;
}

const AssignedFormTemplatesPage: React.FC = () => {
  const [forms, setForms] = useState<AssignedForm[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentForm, setCurrentForm] = useState<AssignedForm | null>(null);
  const [jsonError, setJsonError] = useState<string | null>(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });
  const [deleteConfirmation, setDeleteConfirmation] = useState<{
    open: boolean;
    formId: string | null;
  }>({
    open: false,
    formId: null,
  });

  useEffect(() => {
    fetchForms();
  }, []);

  const fetchForms = async () => {
    const { data, error } = await supabase
      .rpc('get_active_forms');
  
    if (error) {
      console.error(error);
      setSnackbar({
        open: true,
        message: "Error fetching forms: " + error.message,
        severity: "error",
      });
    } else {
      //first serialize json schema
      data.forEach((form: any) => {
        form.form_schema = JSON.stringify(form.form_schema, null, 2);
      }
      );
      setForms(data);
    }
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleOpenDialog = (form: AssignedForm | null = null) => {
    setCurrentForm(form);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setCurrentForm(null);
    setOpenDialog(false);
  };

  const handleDeleteForm = async (id: string) => {
    setDeleteConfirmation({ open: true, formId: id });
  };

  const validateJson = () => {
    const input = currentForm?.form_schema || "{}";
    let parsedObject;

    try {
      // First, try to evaluate the input as a JavaScript object literal
      // eslint-disable-next-line no-new-func
      parsedObject = new Function(`return ${input}`)();

      // If successful, convert the object to a JSON string
      const jsonString = JSON.stringify(parsedObject);

      // Parse the JSON string to ensure it's valid JSON
      JSON.parse(jsonString);

      // Update the form schema with the valid JSON string
      setCurrentForm(
        (prevForm) =>
          ({
            ...prevForm,
            form_schema: jsonString,
          } as AssignedForm)
      );

      setJsonError(null);
      return true;
    } catch (error) {
      // If evaluating as JS object fails, try parsing as JSON
      try {
        JSON.parse(input);
        setJsonError(null);
        return true;
      } catch (jsonError) {
        setJsonError("Invalid input: " + (error as Error).message);
        return false;
      }
    }
  };

  const handleSaveForm = async () => {
    if (!currentForm) return;

    if (!validateJson()) {
      setSnackbar({
        open: true,
        message: "Invalid JSON schema",
        severity: "error",
      });
      return;
    }

    let formSchemaObject;
    try {
      formSchemaObject = JSON.parse(currentForm.form_schema as string);
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to parse form schema",
        severity: "error",
      });
      return;
    }

    if (currentForm.id) {
      // Update existing form
      const { error } = await supabase
        .from("assignedform")
        .update({
          name: currentForm.name,
          description: currentForm.description,
          form_schema: formSchemaObject,
        })
        .eq("id", currentForm.id);

      if (error) {
        setSnackbar({
          open: true,
          message: "Error updating form",
          severity: "error",
        });
      } else {
        setSnackbar({
          open: true,
          message: "Form updated successfully",
          severity: "success",
        });
      }
    } else {
      // Create new form
      const { error } = await supabase.from("assignedform").insert([
        {
          name: currentForm.name,
          description: currentForm.description,
          form_schema: formSchemaObject,
        },
      ]);

      if (error) {
        setSnackbar({
          open: true,
          message: "Error creating form",
          severity: "error",
        });
      } else {
        setSnackbar({
          open: true,
          message: "Form created successfully",
          severity: "success",
        });
      }
    }

    handleCloseDialog();
    fetchForms();
  };

  const confirmDelete = async () => {
    if (!deleteConfirmation.formId) return;
  
    const { error } = await supabase
      .rpc('soft_delete_form', { form_id: deleteConfirmation.formId });
  
    if (error) {
      setSnackbar({
        open: true,
        message: "Error deleting form: " + error.message,
        severity: "error",
      });
    } else {
      setSnackbar({
        open: true,
        message: "Form deleted successfully",
        severity: "success",
      });
      fetchForms(); // Refresh the list of forms
    }
  
    setDeleteConfirmation({ open: false, formId: null });
  };
  const cancelDelete = () => {
    setDeleteConfirmation({ open: false, formId: null });
  };

  return (
    <Box className="assigned-forms-page">
      <Banner
        icon={AssignmentIcon}
        title="Assigned Forms"
        subtitle={`You have ${forms.length} assigned forms.`}
        buttons={<Button variant="contained" color="primary" onClick={() => handleOpenDialog()}>Create Form</Button>}
      />
      <Grid container spacing={3} className="forms-list">
        {forms.map((form) => (
          <Grid item xs={12} sm={6} md={4} key={form.id}>
            <Paper className="form-item">
              <Typography variant="h6">{form.name}</Typography>
              <Typography variant="body2">{form.description}</Typography>
              <Box className="form-actions">
                <IconButton onClick={() => handleOpenDialog(form)}><EditIcon /></IconButton>
                <IconButton onClick={() => handleDeleteForm(form.id)}><DeleteIcon /></IconButton>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{currentForm?.id ? 'Edit Form' : 'Create New Form'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Form Name"
            fullWidth
            value={currentForm?.name || ''}
            onChange={(e) => setCurrentForm({ ...currentForm, name: e.target.value } as AssignedForm)}
          />
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            multiline
            rows={4}
            value={currentForm?.description || ''}
            onChange={(e) => setCurrentForm({ ...currentForm, description: e.target.value } as AssignedForm)}
          />
          <TextField
            margin="dense"
            label="Form Schema (JSON)"
            fullWidth
            multiline
            rows={6}
            value={currentForm?.form_schema || ''}
            onChange={(e) => setCurrentForm({ ...currentForm, form_schema: e.target.value } as AssignedForm)}
            error={!!jsonError}
            helperText={jsonError}
          />
          <Button onClick={validateJson}>Validate JSON</Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSaveForm}>Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteConfirmation.open}
        onClose={cancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <Typography id="alert-dialog-description">
            Are you sure you want to delete this form? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete}>Cancel</Button>
          <Button onClick={confirmDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AssignedFormTemplatesPage;
